import { AdblockDetector } from "adblock-detector";
import { useEffect, useState } from "react";

export default function useAdblockDetector() {
  const [isAdblockEnabled, setIsAdblockEnabled] = useState(false);
  const [startDetect, setStartDetect] = useState(true);

  useEffect(() => {
    if (startDetect) {
      const adbDetector = new AdblockDetector();
      const userHasAdblock = adbDetector.detect();
      setStartDetect(false);
      setIsAdblockEnabled(userHasAdblock);
    }
  }, [startDetect]);

  return isAdblockEnabled;
}
