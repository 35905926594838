import authenticatedFetch from "@/data-access/core/authenticatedFetch";
import { type CampaignRead } from "@/store/generated-apis/campaignApi";
import { selectChosenCampaign } from "@/store/selectors/builderSelector";
import type { AppDispatch, RootState } from "@/store/store";
import type { IAd, IAdMetrics, IAnalytics, IComments } from "@/types/ad";
import type { IArticle } from "@/types/article";
import { CampaignStatus } from "@/types/campaign/campaign";
import type { IDemographics } from "@/types/campaign/demographics";
import type { NavigateFunction } from "react-router-dom";
import { toast } from "sonner";
import { types } from "../../types/types";
import useAuth from "../authSlice";

export const saveArticle = (article: IArticle) => ({
  type: types.builderGenerateArticle,
  payload: article,
});

export const saveCampaign = (campaign: CampaignRead) => ({
  type: types.builderGenerateCampaign,
  payload: campaign,
});

export const saveAd = (ad: IAd | undefined) => ({
  type: types.builderGenerateAd,
  payload: ad,
});

export const showSpinner = (show: boolean) => ({
  type: types.builderShowSpinner,
  payload: show,
});

export const saveAnalytics = (analytics: IAnalytics) => ({
  type: types.builderLoadAnalytics,
  payload: analytics,
});

export const saveDemographics = (demographics: IDemographics[]) => ({
  type: types.builderLoadDemographics,
  payload: demographics,
});

export const saveCampaignComments = (comments: IComments[]) => ({
  type: types.builderLoadComments,
  payload: comments,
});

export const saveAdMetrics = (metrics: IAdMetrics) => ({
  type: types.builderLoadMetrics,
  payload: metrics,
});

export const selectCampaign = (selectedCampaignId?: number) => ({
  type: types.builderSelectCampaign,
  payload: selectedCampaignId,
});

export const refreshSelectedCampaign = (campaign: CampaignRead) => ({
  type: types.builderRefreshCampaign,
  payload: campaign,
});

export const updateCampaignAction = (campaign: CampaignRead) => ({
  type: types.builderUpdateCampaign,
  payload: campaign,
});

export const setLeftSidebarVisible = (isVisible: boolean) => ({
  type: types.builderSetSidebarVisible,
  payload: isVisible,
});

export const generateArticle = (
  url: string,
  name?: string,
  channel?: string,
  navigate?: NavigateFunction,
) => {
  // biome-ignore lint/suspicious/noExplicitAny: <any any any day we will remediate these>
  return async (dispatch: AppDispatch | any) => {
    const user = useAuth.getState().user;
    const tenant = user?.tenants?.[0]?.slug;
    try {
      dispatch(selectCampaign());
      dispatch(showSpinner(true));
      const resp = await authenticatedFetch(`articles/search/?url=${url}`, {
        tenant,
        method: "GET",
      });
      const body = await resp.json();

      if (resp.ok) {
        dispatch(saveArticle(body));
        dispatch(amplifyArticle(body.id, name, channel, navigate));
      } else {
        toast.error("An error ocurred while generating your article");
      }
    } catch (error) {
      toast.error("An error ocurred while generating your article");
      dispatch(showSpinner(false));
    }
  };
};

export const amplifyArticle = (
  articleId: number,
  name?: string,
  channel?: string,
  navigate?: NavigateFunction,
  isNewCampaign = true,
) => {
  // biome-ignore lint/suspicious/noExplicitAny: <any? might as well use javascript>
  return async (dispatch: AppDispatch | any) => {
    const user = useAuth.getState().user;
    const tenant = user?.tenants?.[0]?.slug;
    try {
      dispatch(showSpinner(true));
      const resp = await authenticatedFetch(`articles/${articleId}/amplify/`, {
        tenant,
        method: "GET",
      });
      const body = await resp.json();
      if (resp.ok) {
        dispatch(saveArticle(body));
      } else {
        toast.error("An error ocurred while generating the article summary");
      }
      // Generate ad, no matter if this request failed
      if (isNewCampaign) {
        dispatch(
          generateCampaign(
            body.id,
            body.headline,
            body?.images?.[0]?.id,
            body?.publisher?.logos?.[0]?.id,
            name,
            channel,
            navigate,
          ),
        );
      }
    } catch (error) {
      toast.error("An error ocurred while generating the article summary");
    } finally {
      dispatch(showSpinner(false));
    }
  };
};

export const generateCampaign = (
  article: number,
  headline: string,
  image?: number,
  logoID?: number,
  name?: string,
  channel = "linkedin",
  navigate?: NavigateFunction,
) => {
  // biome-ignore lint/suspicious/noExplicitAny: <legacy any, do not make this a habit>
  return async (dispatch: AppDispatch | any) => {
    const user = useAuth.getState().user;
    const tenant = user?.tenants?.[0]?.slug;
    try {
      dispatch(showSpinner(true));
      const resp = await authenticatedFetch("campaigns/", {
        tenant,
        data: {
          name,
          channel,
        },
        method: "POST",
      });
      const body = await resp.json();

      if (resp.ok) {
        dispatch(saveCampaign(body));
        dispatch(
          generateAd(body.id, article, headline, image, logoID, navigate),
        );
      } else {
        toast.error("An error ocurred while generating your campaign");
      }
    } catch (error) {
      toast.error("An error ocurred while generating your campaign");
    } finally {
      dispatch(showSpinner(false));
    }
  };
};

export const generateAd = (
  campaign: number,
  article: number,
  headline: string,
  image?: number,
  logoID?: number,
  navigate?: NavigateFunction,
) => {
  return async (dispatch: AppDispatch) => {
    const user = useAuth.getState().user;
    const tenant = user?.tenants?.[0]?.slug;
    const tenantName = user?.tenants?.[0]?.name;
    try {
      dispatch(showSpinner(true));
      const resp = await authenticatedFetch("campaigns/ads/", {
        tenant,
        data: {
          campaign,
          article,
          headline,
          image,
          logo: logoID,
          tenant: tenantName,
        },
        method: "POST",
      });
      const body = await resp.json();

      if (resp.ok) {
        dispatch(saveAd(body));
        if (navigate) {
          navigate(
            `/campaigns/${campaign}/articles/${article}/preview/${body.id}`,
          );
        }
      } else {
        toast.error("An error ocurred while generating your campaign");
      }
    } catch (error) {
      toast.error("An error ocurred while generating your campaign");
    } finally {
      dispatch(showSpinner(false));
    }
  };
};

export const refreshCampaign = (campaignId?: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const selectedCampaignId =
      campaignId ?? (selectChosenCampaign(state)?.id || 0);
    const user = useAuth.getState().user;
    const tenant = user?.tenants?.[0]?.slug;

    try {
      const resp = await authenticatedFetch(
        `campaigns/${selectedCampaignId}/`,
        {
          tenant,
        },
      );
      const body = await resp.json();

      if (resp.ok) {
        dispatch(refreshSelectedCampaign(body));
      } else {
        toast.error("An error ocurred while loading your campaigns");
      }
    } catch (error) {
      toast.error("An error ocurred while loading your campaigns");
    }
  };
};

export const duplicateCampaign = (campaignId: number) => {
  // biome-ignore lint/suspicious/noExplicitAny: <legacy any, never use new any>
  return async (dispatch: AppDispatch | any) => {
    const user = useAuth.getState().user;
    const tenant = user?.tenants?.[0]?.slug;

    try {
      dispatch(showSpinner(true));
      const resp = await authenticatedFetch(
        `campaigns/${campaignId}/duplicate/`,
        {
          tenant,
          method: "POST",
        },
      );
      const body = await resp.json();

      if (resp.ok) {
        dispatch(saveCampaign(body));
        return true;
      }
      toast.error("An error ocurred while duplicating your campaign");
    } catch (error) {
      toast.error("An error ocurred while duplicating your campaign");
    } finally {
      dispatch(showSpinner(false));
    }
    return false;
  };
};

export const updateCampaignStatus = (
  campaignId: number,
  status: CampaignStatus,
) => {
  return async (dispatch: AppDispatch) => {
    const user = useAuth.getState().user;
    const tenant = user?.tenants?.[0]?.slug;

    try {
      const resp = await authenticatedFetch(`campaigns/${campaignId}/`, {
        tenant,
        data: {
          status,
        },
        method: "PATCH",
      });
      const body = await resp.json();

      if (resp.ok) {
        dispatch(updateCampaignAction(body));
        return true;
      }
      toast.error("An error ocurred while unpublishing your campaign");
    } catch (error) {
      toast.error("An error ocurred while unpublishing your campaign");
    }
    return false;
  };
};
export const loadAnalytics = (campaignId: number) => {
  return async (dispatch: AppDispatch) => {
    const user = useAuth.getState().user;
    const tenant = user?.tenants?.[0]?.slug;
    try {
      const resp = await authenticatedFetch(
        `campaigns/${campaignId}/analytics/`,
        {
          tenant,
        },
      );
      const body = await resp.json();
      if (resp.ok) {
        dispatch(saveAnalytics(body));
      } else {
        toast.error("An error ocurred while loading analytics");
      }
    } catch (error) {
      toast.error("An error ocurred while loading analytics");
    }
  };
};

export const loadDemographics = (campaignId: number) => {
  return async (dispatch: AppDispatch) => {
    const user = useAuth.getState().user;
    const tenant = user?.tenants?.[0]?.slug;

    try {
      const resp = await authenticatedFetch(
        `campaigns/${campaignId}/demographics/`,
        {
          tenant,
        },
      );
      const body = await resp.json();
      if (resp.ok) {
        dispatch(saveDemographics(body));
      } else {
        toast.error("An error ocurred while loading demographics");
      }
    } catch (error) {
      toast.error("An error ocurred while loading demographics");
    }
  };
};

export const loadCampaignComments = (adId: number) => {
  return async (dispatch: AppDispatch) => {
    if (adId === 0 || !adId) {
      dispatch(saveCampaignComments([]));
    } else {
      const user = useAuth.getState().user;
      const tenant = user?.tenants?.[0]?.slug;

      try {
        const resp = await authenticatedFetch(
          `campaigns/ads/${adId}/comments/`,
          {
            tenant,
          },
        );
        const body = await resp.json();
        if (resp.ok) {
          dispatch(saveCampaignComments(body));
        } else {
          toast.error("An error ocurred while loading comments");
        }
      } catch (error) {
        toast.error("An error ocurred while loading comments");
      }
    }
  };
};

export const loadAdMetrics = (adId: number) => {
  return async (dispatch: AppDispatch) => {
    if (adId === 0 || !adId) {
      dispatch(
        saveAdMetrics({
          avg_ctr: 0,
          avg_cpr: 0,
          total_spend: "",
          total_impressions: 0,
          total_clicks: 0,
          total_reactions: 0,
          total_shares: 0,
          total_comments: 0,
          total_follows: 0,
        }),
      );
    } else {
      const user = useAuth.getState().user;
      const tenant = user?.tenants?.[0]?.slug;

      try {
        const resp = await authenticatedFetch(
          `campaigns/ads/${adId}/analytics/`,
          {
            tenant,
          },
        );
        const body = await resp.json();
        if (resp.ok) {
          dispatch(saveAdMetrics(body));
        } else {
          toast.error("An error ocurred while loading metrics");
        }
      } catch (error) {
        toast.error("An error ocurred while loading metrics");
      }
    }
  };
};
