import { graphql } from "../graphql";

export const tierFragment = graphql(`
  fragment Tier on TierConfigurationType {
    tier
    lowerBound
    upperBound
  }
`);

export type TierFragmentType = ReturnType<
  Exclude<typeof tierFragment.__apiType, undefined>
>;

export const tierConfigurationFragment = graphql(
  `
  fragment TierConfig on TierConfigurationResponseType {
      __typename
      tiers {
       ...Tier
      }
  }`,
  [tierFragment],
);

export const TIER_CONFIGUTATION_QUERY = graphql(
  `
  query TierConfiguration {
    tierConfiguration {
      ...TierConfig
    }
  }
`,
  [tierConfigurationFragment],
);

export type TierConfigurationQuery = ReturnType<
  Exclude<typeof TIER_CONFIGUTATION_QUERY.__apiType, undefined>
>;

export const CREATE_TIER_CONFIGURATION = graphql(
  `
  mutation CreateTierConfiguration($input: TierConfigurationInput!) {
    createTierConfiguration(input: $input) {
      ... on TierConfigurationResponseType {
        ...TierConfig
      }
    }
  }
`,
  [tierConfigurationFragment],
);

export const UPDATE_TIER_CONFIGURATION = graphql(
  `
  mutation UpdateTierConfiguration($input: TierConfigurationInput!) {
    updateTierConfiguration(input: $input) {
      ... on TierConfigurationResponseType {
        ...TierConfig
      }
    }
  }
`,
  [tierConfigurationFragment],
);

export const CREATE_PUBLISHER_CONFIGURATION = graphql(
  `
  mutation CreatePublisherConfiguration($input: CreatePublisherConfigurationInput!) {
    createPublisherConfiguration(input: $input) {
      ... on PublisherConfigurationType {
        __typename
        tierConfiguration {
          ...Tier
        }
      }
    }
  }
`,
  [tierFragment],
);

export const UPDATE_PUBLISHER_CONFIGURATION = graphql(
  `
  mutation UpdatePublisherConfiguration($input: UpdatePublisherConfigurationInput!) {
    updatePublisherConfiguration(input: $input) {
      ... on PublisherConfigurationType {
        __typename
        tierConfiguration {
          ...Tier
        }
      }
    }
  }
`,
  [tierFragment],
);

export const READERSHIP_AND_DOMAIN_QUERY = graphql(
  `
  query ReadershipAndDomain {
    readershipConfiguration {
      ...TierConfig
      tenant {
        enableReadership
      }
    }
    tierConfiguration {
      ...TierConfig
      tenant {
        enableCustomTierScoring
      }
    }
  }
`,
  [tierConfigurationFragment],
);

export type ReadershipConfigurationType = ReturnType<
  Exclude<typeof READERSHIP_AND_DOMAIN_QUERY.__apiType, undefined>
>["readershipConfiguration"];

export type TierConfigurationType = ReturnType<
  Exclude<typeof READERSHIP_AND_DOMAIN_QUERY.__apiType, undefined>
>["tierConfiguration"];

export const PUBLISHER_CONFIGURATION_QUERY = graphql(`
  query PublisherConfiguration($tier: TierType!) {
    publisherConfiguration(tier: $tier) {
      publishers {
          id
          name
          url
          logos {
            image {
              url
            }
          }
        }
    }
  }
`);

export const TOGGLE_DOMAIN_OR_READERSHIP = graphql(`
  mutation ToggleReadershipOrDomain($domain: Boolean!, $readership: Boolean!) {
    toggleDomainOrReadership(domain: $domain, readership: $readership) {
    ... on TenantType {
        id
        enableCustomTierScoring
        enableReadership
    }
    }
  }
`);

export const UPSERT_READERSHIP_CONFIGURATION = graphql(
  `
  mutation UpsertReadershipConfiguration($input: TierConfigurationInput!) {
    upsertReadershipConfiguration(input: $input) {
      ... on TierConfigurationResponseType {
        ...TierConfig
      }
    }
  }
`,
  [tierConfigurationFragment],
);

export type PublisherConfigurationType =
  | ReturnType<
      Exclude<typeof PUBLISHER_CONFIGURATION_QUERY.__apiType, undefined>
    >
  | undefined;
