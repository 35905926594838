export const TIER_2_MAX = 80;
export const TIER_3_MAX = 60;
export const TIER_1_COLOR = "#FFD700";
export const TIER_2_COLOR = "#C0C0C0";
export const TIER_3_COLOR = "#CD7F32";

export const formatLastUpdateDate = (lastUpdateDate: string) => {
  const currentDate = new Date();
  const date = new Date(lastUpdateDate);

  const diffInMilliseconds = Math.abs(+currentDate - +date);
  const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

  const formattedDate = `${getDayOfWeek(date)} at ${formatTime(date)}`;

  if (diffInMinutes < 1440) {
    const hours = Math.floor(diffInMinutes / 60);
    const minutes = diffInMinutes % 60;
    const hoursText = hours > 0 ? `${hours} hours` : "";
    return `updated last ${hoursText} ${minutes} minutes ago`;
  }

  return `updated last ${formattedDate}`;
};

export const getDayOfWeek = (date: Date) => {
  const options = { weekday: "long" as const };
  return new Intl.DateTimeFormat("en-US", options).format(date);
};

export const hasSecondSection = (article: {
  summary?: string;
  articleMentions?:
    | {
        location: string;
        term: string;
        snippet: string;
      }[]
    | null
    | undefined;
  searchHit?:
    | {
        mention: string;
        term: string;
      }
    | undefined;
}) => {
  return !!(
    article.summary ||
    Boolean(article.articleMentions?.length) ||
    article.searchHit
  );
};

export const transformSummary = (summary: string) => {
  const lines = summary.split("\n");
  const paragraphs = [];

  const unwantedPhrasesRegex =
    /\b(not (explicitly )?mentioned?|no (explicit mentions?|mention of|further information about|details provided on|additional points mentioned|specific mentions?|detailed information|specific information provided)|does not (explicitly )?(mention|provide further details|specify|any information)|(?:no|not) .* (specific (role|responsibilities?|details?|information)|detailed information)|(no|not enough|insufficient) .* (information|details?)|uncertain details|details are unclear|is not (mentioned|provided)|no mention)\b/i;

  for (const line of lines) {
    const trimmedLine = line.trim();

    if (trimmedLine !== "" && !unwantedPhrasesRegex.test(trimmedLine)) {
      // If the line starts with "• ", preserve the bullet point
      if (trimmedLine.startsWith("• ")) {
        paragraphs.push(trimmedLine);
      } else {
        paragraphs.push(trimmedLine);
      }
    }
  }

  return paragraphs;
};

export const formatTime = (date: Date) => {
  const options = {
    hour: "numeric" as const,
    minute: "2-digit" as const,
    hour12: true,
  };
  return new Intl.DateTimeFormat("en-US", options).format(date);
};

export const getDaysParametersFromUrl = (): number => {
  const params = new URLSearchParams(window.location.search);
  const days = params.get("Days");
  return days ? parseInt(days) : 30;
};

export const getSortByFromUrl = () => {
  const params = new URLSearchParams(window.location.search);
  const sortBy = params.get("SortBy");
  return sortBy || "SCORE_DESC";
};

export const getSearchTermsFromUrl = () => {
  const params = new URLSearchParams(window.location.search);
  const searchTerms = decodeURIComponent(params.get("SearchTerms") || "");
  return searchTerms;
};

export const setSearchTermsToUrl = (searchTerms: string) => {
  const params = new URLSearchParams(window.location.search);
  params.set("SearchTerms", encodeURIComponent(searchTerms));
  const newUrl = `${window.location.pathname}?${params.toString()}`;
  window.history.replaceState({}, "", newUrl);
};

export const setDaysParametersToUrl = (days: number) => {
  const params = new URLSearchParams(window.location.search);
  params.set("Days", days.toString());
  const newUrl = `${window.location.pathname}?${params.toString()}`;
  window.history.replaceState({}, "", newUrl);
};

export const setSortByToUrl = (sortBy: string) => {
  const params = new URLSearchParams(window.location.search);
  params.set("SortBy", sortBy);
  const newUrl = `${window.location.pathname}?${params.toString()}`;
  window.history.replaceState({}, "", newUrl);
};

export type FilterMap = {
  [key: string]: string[];
};

export const getFilterParamsFromUrl = () => {
  const params = new URLSearchParams(window.location.search);
  const filtersString = params.get("filters");

  if (filtersString) {
    return filtersString.split(";").reduce((acc: FilterMap, filterPair) => {
      const [key, values] = filterPair.split(":");
      if (key && values) {
        acc[key] = values.split(",");
      }
      return acc;
    }, {});
  }

  return {};
};

export const setFilterParamsToUrl = (filters: string) => {
  const params = new URLSearchParams(window.location.search);
  params.set("filters", filters);
  const newUrl = `${window.location.pathname}?${params.toString()}`;
  window.history.replaceState({}, "", newUrl);
};

export const formatNumber = (num: number): string => {
  if (num === 0) {
    return "0";
  }
  const units = ["", "K", "M", "B", "T"];
  const k = 1000.0;
  const magnitude = Math.floor(Math.log(num) / Math.log(k));
  let formattedNumber = (num / k ** magnitude).toFixed(1);

  // Convert to a number and back to string to remove unnecessary trailing zeros
  formattedNumber = parseFloat(formattedNumber).toString();

  return `${formattedNumber}${units[magnitude]}`;
};
