import * as AvatarPrimitive from "@radix-ui/react-avatar";
import * as React from "react";

import { cn } from "@/lib/utils";

const Avatar = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Root
    ref={ref}
    className={cn(
      "relative inline-flex h-6 w-6 shrink-0 overflow-hidden rounded-full",
      className,
    )}
    {...props}
  />
));
Avatar.displayName = AvatarPrimitive.Root.displayName;

const AvatarImage = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Image>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Image
    ref={ref}
    className={cn("aspect-square h-full w-full", className)}
    {...props}
  />
));
AvatarImage.displayName = AvatarPrimitive.Image.displayName;

const iconColors = [
  "purple",
  "rose",
  "yellow",
  "green",
  "orange",
  "pink",
  "blue",
];

function generateNumericHashFromString(inputString: string) {
  let numericHash = 0;
  for (let index = 0; index < inputString.length; index++) {
    const charCode = inputString.charCodeAt(index);
    numericHash = ((numericHash << 5) - numericHash + charCode) | 0;
  }
  return numericHash;
}

const AvatarFallback = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Fallback>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>
>(({ className, children, ...props }, ref) => {
  const childrenArray = React.Children.toArray(children);
  const childrenAsString = childrenArray.join("");
  const hash = generateNumericHashFromString(childrenAsString);
  const colorIndex = Math.abs(hash) % iconColors.length;
  const color = iconColors[colorIndex];
  return (
    <AvatarPrimitive.Fallback
      ref={ref}
      className={cn(
        `flex h-full w-full items-center text-xs justify-center rounded-full text-${color}-100 bg-${color}-900`,
        className,
      )}
      {...props}
    >
      {children}
    </AvatarPrimitive.Fallback>
  );
});

AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;

export { Avatar, AvatarImage, AvatarFallback };
