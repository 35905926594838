import type { AdRead, CampaignRead } from "../generated-apis/campaignApi";
import type { BuilderState } from "../reducers/builderReducer";
import type { RootState } from "../store";

const getBuilder = (state: RootState): BuilderState => state.builder;
const getCampaigns = (state: RootState): CampaignRead[] =>
  state.builder.campaigns ?? [];
const getSelectedCampaign = (state: RootState) => {
  const selectedCampaignId = getBuilder(state).selectedCampaignId;
  const campaigns = getCampaigns(state);
  return campaigns.find((campaign) => campaign.id === selectedCampaignId);
};

export const selectLoadingArticle = (state: RootState) =>
  getBuilder(state).showSpinner;
export const selectArticle = (state: RootState) => getBuilder(state).article;
export const selectCampaigns = (state: RootState) => getCampaigns(state);
export const selectArticlePublisher = (state: RootState) =>
  getBuilder(state).article?.publisher;
export const selectArticlePublisherName = (state: RootState) =>
  getBuilder(state).article?.publisher?.name;
export const selectChosenCampaign = (state: RootState) =>
  getSelectedCampaign(state);
export const selectFirstCampaignAd = (state: RootState): AdRead | undefined => {
  return getSelectedCampaign(state)?.ads?.[0];
};
export const selectArticleImages = (state: RootState) =>
  getBuilder(state).article?.images;

export const selectAdImage = (state: RootState): string => {
  const campaign = getSelectedCampaign(state);
  const articleImages = selectArticleImages(state);

  if (!campaign?.ads?.[0] || !articleImages) return "";
  if (!articleImages) return "";

  const imgId = campaign.ads[0].image;
  const image = articleImages.find((img) => img.id === imgId);

  return image?.image?.large_portrait_crop ?? "";
};
