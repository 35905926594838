import * as Sentry from "@sentry/react";
import { StrictMode, useState } from "react";
import ReactDOM from "react-dom/client";
import { Button } from "./components/button.tsx";
import { Toaster } from "./components/ui/feedback/sonner.tsx";
import { isProduction } from "./config.ts";
import "./index.css";
import { cn } from "./lib/utils/cn.ts";
import Providers from "./providers.tsx";
import Router from "./router.tsx";

interface ErrorDetailsProps {
  error: Error | unknown;
  componentStack: string;
}

const ErrorDetails: React.FC<ErrorDetailsProps> = ({
  error,
  componentStack,
}) => {
  const errorString =
    error instanceof Error ? error.toString() : JSON.stringify(error);

  return (
    <details
      className={cn(
        "bg-rose-50",
        "p-4",
        "rounded",
        "border",
        "border-rose-200",
        "shadow-sm",
        "mb-8",
        "text-rose-900",
      )}
    >
      <summary
        className={cn("cursor-pointer", "text-lg", "font-bold", "text-red-600")}
      >
        Error details (Non Production Only)
      </summary>
      <pre className={cn("overflow-x-auto", "p-2", "bg-red-50", "rounded")}>
        <code
          className={cn(
            "whitespace-pre",
            "break-all",
            "text-xs",
            "text-red-600",
          )}
        >
          {errorString}
          <br />
          {componentStack}
        </code>
      </pre>
    </details>
  );
};

interface ErrorHandlerProps {
  error: Error | unknown;
  componentStack: string;
  eventId: string;
  resetError: () => void;
}

const ErrorHandler: React.FC<ErrorHandlerProps> = ({
  error,
  componentStack,
  eventId,
  resetError,
}) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(eventId);
    setCopied(true);
  };

  return (
    <div>
      <div
        className={cn(
          "fixed",
          "top-1/2",
          "left-1/2",
          "transform",
          "-translate-x-1/2",
          "-translate-y-1/2",
          "z-50",
          "bg-white",
          "rounded",
          "shadow-md",
          "py-8",
          "px-10",
          "border",
          "border-gray-200",
          "max-w-4xl",
          "w-4/5",
          "mx-4",
        )}
      >
        <h1
          className={cn(
            "text-3xl",
            "font-serif",
            "text-gray-800",
            "font-bold",
            "mb-4",
          )}
        >
          Something Went Wrong!
        </h1>
        <p className={cn("text", "text-gray-600", "mb-8")}>
          Don't worry, we've been notified and our team is already working
          diligently to resolve the issue. We appreciate your patience and will
          get back to you soon.
        </p>
        {!isProduction && (
          <ErrorDetails error={error} componentStack={componentStack} />
        )}
        <Button onPress={resetError}>Refresh Page</Button>
        <Button
          className="ml-2"
          variant="secondary"
          onPress={() => window.location.replace("/")}
        >
          Go Home
        </Button>
      </div>
      <p
        className={cn(
          "text-xs",
          "text-gray-400",
          "mt-4 absolute",
          "bottom-0 left-2",
        )}
      >
        If you'd like to report this issue, please copy and share the following
        event ID with our support team:
        <br />
        <code className={cn("text-xs")}>
          {eventId}
          <Button
            className={cn("text-xs", "ml-2", "cursor-pointer")}
            onPress={handleCopy}
            variant={"link"}
          >
            {copied ? "Copied!" : "Copy"}
          </Button>
        </code>
      </p>
    </div>
  );
};

const App = () => {
  window.addEventListener("vite:preloadError", () => {
    window.location.reload();
  });

  return (
    <Sentry.ErrorBoundary
      fallback={({ error, resetError, componentStack, eventId }) => (
        <ErrorHandler
          error={error}
          resetError={resetError}
          componentStack={componentStack}
          eventId={eventId}
        />
      )}
    >
      <Providers>
        <Router />
        <Toaster />
      </Providers>
    </Sentry.ErrorBoundary>
  );
};

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <StrictMode>
    <App />
  </StrictMode>,
);

export default App;
