import { cn } from "@/lib/utils";

const BackgroundGradientLayout = ({
  children,
  className = "",
}: { children: React.ReactNode | React.ReactNode[]; className?: string }) => {
  return (
    <div className={cn("w-full h-full overflow-hidden relative", className)}>
      {/* biome-ignore lint/a11y/noSvgWithoutTitle: <not needed for this> */}
      <svg
        width="513"
        height="464"
        viewBox="0 0 513 464"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute top-0 right-0"
      >
        <g filter="url(#filter0_f_2_645)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M308.847 23.4786C261.029 52.9689 194.385 74.8053 200.438 130.659C207.927 199.775 265.324 260.855 334.809 263.064C395.329 264.988 532.233 217.968 557.877 163.117C586.139 102.668 489.26 5.92384 438.771 -37.7062C388.91 -80.793 364.936 -11.1126 308.847 23.4786Z"
            fill="url(#paint0_linear_2_645)"
          />
        </g>
        <defs>
          <filter
            id="filter0_f_2_645"
            x="0.0527649"
            y="-251.348"
            width="762.893"
            height="714.469"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="auto"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="100"
              result="effect1_foregroundBlur_2_645"
            />
          </filter>
          <linearGradient
            id="paint0_linear_2_645"
            x1="462.902"
            y1="125.749"
            x2="118.811"
            y2="-6.59349"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="oklch(74.74% 0.18 57.36 / 60%)" />
            <stop
              offset={0.862947}
              stopColor="oklch(96.05% 0.005 258.32 / 50%)"
            />
            <stop offset={1} stopColor="oklch(96.05% 0.005 258.32 / 0%)" />
          </linearGradient>
        </defs>
      </svg>
      {/* biome-ignore lint/a11y/noSvgWithoutTitle: <not needed for this> */}
      <svg
        width="708"
        height="515"
        viewBox="0 0 708 515"
        fill="none"
        className="absolute bottom-0 left-0 overflow-hidden"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_f_2_644)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M135.963 332.092C202.49 269.318 264.912 173.715 350.198 206.769C455.734 247.671 527.605 363.673 501.799 473.877C479.322 569.861 357.641 572.284 260.575 589.499C153.603 608.472 32.3334 608.414 -15.0047 510.629C-61.7533 414.062 57.9293 405.722 135.963 332.092Z"
            fill="url(#paint1_linear_1_644)"
          />
        </g>
        <defs>
          <filter
            id="filter0_f_2_644"
            x="-225.522"
            y="0.0078125"
            width="932.593"
            height="799.308"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="auto"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="100"
              result="effect1_foregroundBlur_2_644"
            />
          </filter>
          <linearGradient
            id="paint1_linear_1_644"
            x1="120.184"
            y1="302.511"
            x2="408.173"
            y2="590.5"
          >
            <stop stopColor="oklch(67.92% 0.203 42.1 / 30%)" />
            <stop offset="1" stopColor="oklch(13.92% 0.04 42.1 / 0%)" />
          </linearGradient>
        </defs>
      </svg>
      {/* biome-ignore lint/a11y/noSvgWithoutTitle: <not needed for this> */}
      <svg
        width="342"
        height="515"
        viewBox="0 0 342 515"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="bottom-0 left-0 absolute"
      >
        <g filter="url(#filter0_f_2_643)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.6482 200.54C88.0852 200.168 91.0183 289.304 109.904 349.717C127.61 406.358 165.529 470.43 121.481 510.3C66.0515 560.472 -25.424 572.476 -81.237 522.728C-129.073 480.091 -77.5658 411.504 -57.5819 350.697C-37.8904 290.78 -38.5617 200.91 24.6482 200.54Z"
            fill="url(#paint0_linear_2_643)"
          />
        </g>
        <defs>
          <filter
            id="filter0_f_2_643"
            x="-301"
            y="0.538635"
            width="643"
            height="754"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="auto"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="100"
              result="effect1_foregroundBlur_2_643"
            />
          </filter>
          <linearGradient
            id="paint0_linear_2_643"
            x1="20.5"
            y1="200.539"
            x2="20.5"
            y2="554.539"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={1} stopColor="oklch(67.92% 0.203 42.1 / 30%)" />
          </linearGradient>
        </defs>
      </svg>
      <div className="z-10 relative">{children}</div>
    </div>
  );
};

export default BackgroundGradientLayout;
