import { cn } from "@/lib/utils";
import { CaretDown, CaretRight, type Icon } from "@phosphor-icons/react";
import { createElement, useEffect, useState } from "react";
import { NavLink, useMatch } from "react-router-dom";
import { defaultFilters } from "../news/shared/FilterDays";
import { Button } from "../ui/controls/button";

type ItemType = { id: string | number; name: string }[];

interface DropdownListProps {
  title: "Newsfeeds" | "Campaigns";
  items: ItemType;
  icon: Icon;
  basePath: string;
  open?: boolean;
  collapsed?: boolean;
}

const DropdownList: React.FC<DropdownListProps> = ({
  title,
  items,
  icon,
  basePath,
  open = false,
  collapsed = false,
}) => {
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    if (!open) return;
    setIsOpen(open);
  }, [open]);

  const handleDoubleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setIsOpen((prev) => !prev);
  };

  const navStyle = () => cn("flex items-center h-full");

  const childNavStyle = ({ isActive }: { isActive: boolean }) =>
    cn(
      "flex items-center pl-6 pr-2 py-1 h-8 rounded",
      "transition-colors text-slate-900",
      "hover:bg-slate-200",
      "truncate",
      "items-right",
      "ml-auto",
      isActive && "bg-slate-200/60 font-semibold text-slate-800",
    );

  const dropdownOpen = !collapsed && isOpen;

  const match = useMatch({ path: basePath, end: false });

  const iconSize = collapsed ? 20 : 16;
  const iconClass = collapsed && match ? "fill-slate-700" : "fill-slate-700";
  const iconWeight = collapsed && match ? "regular" : "regular";

  return (
    <>
      <div
        className={cn(
          "flex items-center pr-2 rounded transition-colors text-slate-900",
          !collapsed && match && "bg-slate-200 font-semibold text-slate-800",
          match && "bg-slate-200/75",
          collapsed ? "hover:bg-slate-200/75 h-10" : "hover:bg-slate-200 h-8",
        )}
      >
        {!collapsed &&
          (items.length > 0 ? (
            <Button
              size="icon"
              variant="ghost"
              onClick={() => setIsOpen(!isOpen)}
              className="p-0 pl-1 w-6 pr-0.5"
            >
              {dropdownOpen ? (
                <CaretDown size={16} />
              ) : (
                <CaretRight size={16} />
              )}
            </Button>
          ) : (
            <div className="w-6 pl-1 pr-0.5" />
          ))}
        <NavLink
          to={basePath}
          className={navStyle}
          onDoubleClick={handleDoubleClick}
        >
          <div
            className={cn(
              "flex items-center",
              !collapsed ? "pl-0.5" : "pl-2.5",
            )}
          >
            {icon &&
              createElement(icon, {
                size: iconSize,
                className: iconClass,
                weight: iconWeight,
              })}
            {!collapsed && <span className="ml-2">{title}</span>}
          </div>
        </NavLink>
      </div>
      {dropdownOpen && (
        <div className="flex flex-col gap-0.5 w-full">
          {items.map((item) => (
            <NavLink
              key={item.id}
              to={`${basePath}/${item.id}${defaultFilters()}`}
              className={({ isActive }) => childNavStyle({ isActive })}
            >
              <span className="ml-2 truncate" title={item.name}>
                {item.name}
              </span>
            </NavLink>
          ))}
        </div>
      )}
    </>
  );
};

export default DropdownList;
