import authenticatedFetch from "@/data-access/core/authenticatedFetch";
import type { AppDispatch } from "@/store/store";
import type { UserInfo } from "@/types/user/userInfo";
import { toast } from "sonner";
import { types } from "../../types/types";
import useAuth from "../authSlice";

interface AccountState {
  email?: string;
  first_name?: string;
  last_name?: string;
  password?: string;
  avatar?: string;
}

export const accountReset = () => ({
  type: types.accountReset,
});

const updateAccount = (user: UserInfo) => ({
  type: types.accountUpdate,
  payload: user,
});

export const startUpdateAccount = (user: AccountState, showToast = true) => {
  return async (dispatch: AppDispatch) => {
    try {
      const resp = await authenticatedFetch("auth/user/", {
        data: user,
        method: "PATCH",
      });
      const body = await resp.json();

      if (body.email) {
        window.localStorage.setItem("newsamp_user", JSON.stringify(body));
        useAuth.getState().setUser(body);
        dispatch(updateAccount(body));
        if (showToast) {
          toast.success("Account updated successfully");
        }
      } else {
        if (showToast) {
          toast.error(body.non_field_errors[0]);
        }
      }
    } catch (error) {
      if (showToast) {
        toast.error("An error occured, please try again.");
      }
    }
  };
};

export const setTmpPicture = (picture: string) => ({
  type: types.accountSetTmpPicture,
  payload: picture,
});

export const setTmpLogo = (logo: string) => ({
  type: types.accountUploadCompanyLogo,
  payload: logo,
});

export const uploadCompanyLogo = (logo: string) => {
  return async (dispatch: AppDispatch) => {
    const user = useAuth.getState().user;
    const tenant = user?.tenants?.[0]?.slug;
    try {
      const resp = await authenticatedFetch(`tenants/${tenant}/`, {
        data: { logo },
        method: "PATCH",
      });
      const body = await resp.json();

      if (resp.ok) {
        dispatch(setTmpLogo(body.logo));
        toast.success("Logo updated successfully");
      } else {
        toast.error(body.non_field_errors[0]);
      }
    } catch (error) {
      toast.error("An error occured, please try again.");
    }
  };
};

const updateCompany = (company: { name: string; logo?: string }) => ({
  type: types.accountUpdateCompany,
  payload: company,
});

export const startUpdateCompany = (company: {
  name: string;
  logo?: string;
}) => {
  return async (dispatch: AppDispatch) => {
    const user = useAuth.getState().user;
    const tenant = user?.tenants?.[0]?.slug;
    try {
      const resp = await authenticatedFetch(`tenants/${tenant}/`, {
        data: company,
        method: "PATCH",
      });
      const body = await resp.json();

      if (resp.ok) {
        dispatch(updateCompany(body));
        toast.success("Company updated successfully");
      } else {
        toast.error(body.non_field_errors[0]);
      }
    } catch (error) {
      toast.error("An error occured, please try again.");
    }
  };
};

const loadCompany = (company: { name: string; logo?: string }) => ({
  type: types.accountLoadCompany,
  payload: company,
});

export const startLoadCompany = () => {
  return async (dispatch: AppDispatch) => {
    const user = useAuth.getState().user;
    if (user?.tenants?.length === 0) return;
    const tenant = user?.tenants?.[0]?.slug;
    try {
      const resp = await authenticatedFetch(`tenants/${tenant}/`, {
        tenant: user?.tenants?.[0]?.slug,
      });
      const body = await resp.json();

      if (resp.ok) {
        dispatch(loadCompany(body));
      } else {
        toast.error(body.non_field_errors[0]);
      }
    } catch (error) {
      toast.error("An error occured, please try again.");
    }
  };
};
