import {
  type FilterMap,
  getFilterParamsFromUrl,
  setFilterParamsToUrl,
} from "@/components/news/shared/utils";
import {
  type FeedType,
  type FeedWithAugTypes,
  NewsFeedsSearchQuery,
} from "@/data-access/news/newsFeeds";
import { client } from "@/lib/urqlProvider";
import { getPrettyName } from "@/lib/utils/prettyName";

export const TIER_1 = "Tier 1";
export const TIER_2 = "Tier 2";
export const TIER_3 = "Tier 3";

export type FilterValues = {
  selected: string[];
  relationship: "AND" | "OR" | "OR-ONLY";
  itemCounts: Record<string, number>;
  sorted?: boolean;
  logic: (item: Partial<FeedType>, selection: string) => boolean;
};

export type CategoryFilter = Partial<FilterValues>;

export type CategoryAppliedFilter = Record<string, CategoryFilter>;

export async function filterFeedItems(
  feedItems: Partial<FeedType>[],
  searchTerms: string,
  categoryAppliedFilters: CategoryAppliedFilter,
  feedId?: number,
): Promise<Partial<FeedType>[]> {
  if (shouldReturnOriginalFeed(searchTerms, categoryAppliedFilters)) {
    return Promise.resolve(feedItems);
  }

  const itemdIds = feedItems
    .map((item) => item.articleId)
    .filter((id): id is number => id !== undefined);

  const canSearchRemote = searchTerms.length && feedId;

  const foundSearchHits = canSearchRemote
    ? await fetchSearchData(feedId, searchTerms, itemdIds)
    : false;

  return filterFeedItemsBySearchAndCategory(
    feedItems,
    foundSearchHits,
    categoryAppliedFilters,
    searchTerms,
  );
}

function shouldReturnOriginalFeed(
  searchTerms: string,
  categoryAppliedFilters: CategoryAppliedFilter,
): boolean {
  return !searchTerms.length && !hasAppliedFilters(categoryAppliedFilters);
}

function hasAppliedFilters(
  categoryAppliedFilters: CategoryAppliedFilter,
): boolean {
  return Object.keys(categoryAppliedFilters).some(
    (key) => (categoryAppliedFilters[key]?.selected?.length ?? 0) > 0,
  );
}

async function fetchSearchData(
  feedId: number,
  searchTerm: string,
  selectedFeedItems: number[],
): Promise<Record<string, string>> {
  const minLengthForSearch = 3;

  if (searchTerm?.length < minLengthForSearch) {
    return {};
  }

  const response = await client
    .query(NewsFeedsSearchQuery, {
      feedId,
      searchTerm,
      selectedItems: selectedFeedItems,
    })
    .toPromise();

  if (!response.data) {
    console.error("Issue with GraphQL response");
    return {};
  }

  return response.data.feedSearch.items.reduce(
    (acc, item) => {
      const { id, field } = item;
      const firstContent = field[0]?.content[0];
      if (!firstContent) return acc;
      acc[`${id}`] = firstContent;
      return acc;
    },
    {} as Record<string, string>,
  );
}

const getExcludedAndIncludedTerms = (
  searchTerms: string[],
): {
  includedTerms: string[];
  excludedTerms: string[];
} => {
  const includedTerms: string[] = [];
  const excludedTerms: string[] = [];

  for (const term of searchTerms) {
    if (term.startsWith("-")) {
      excludedTerms.push(term.slice(1));
    } else {
      includedTerms.push(term);
    }
  }

  return { includedTerms, excludedTerms };
};

const getItemSearchHitString = (
  item: Partial<FeedType>,
  foundSearchHits: Record<string, string>,
): { mention: string; term: string } | null => {
  if (!foundSearchHits) return null;

  const itemId = item.id ?? -1;
  const searchHitString = foundSearchHits[`${itemId}`];

  if (!searchHitString) return null;

  const emRegex = /<em><b>(.*?)<\/b><\/em>/g;
  const match = searchHitString.match(emRegex);
  const mention = searchHitString.replace(emRegex, "$1");
  const term = match ? match[0].replace(/<.*?>/g, "") : "";

  return { mention, term };
};

function filterFeedItemsBySearchAndCategory(
  feedItems: Partial<FeedType>[],
  foundSearchHits: Record<string, string> | false,
  categoryAppliedFilters: CategoryAppliedFilter,
  searchTerm: string,
): Partial<FeedType>[] {
  if (!feedItems || feedItems.length === 0) {
    return [];
  }

  const searchTerms = searchTerm.split(/\s+/);
  const { includedTerms, excludedTerms } =
    getExcludedAndIncludedTerms(searchTerms);
  return feedItems
    .filter((item) => {
      if (!item) return false;
      const remoteSearchMatch =
        foundSearchHits &&
        Object.keys(foundSearchHits).includes(`${item.id ?? -1}`);
      const localSearchMatch = matchesSearchTerms(item, includedTerms);
      const excludeMatch = excludesTerms(item, excludedTerms);
      const filterMatch = matchesSelectedFilters(item, categoryAppliedFilters);

      return (
        (remoteSearchMatch || localSearchMatch) && filterMatch && !excludeMatch
      );
    })
    .map((item) => {
      if (foundSearchHits) {
        const searchHit = getItemSearchHitString(item, foundSearchHits);
        return searchHit ? { ...item, searchHit } : { ...item };
      }
      return { ...item };
    });
}

export function processText(item: Partial<FeedType>): string {
  const {
    headline,
    summary,
    articleUrl,
    articleMentions,
    storyPublishers,
    discoveredTags,
  } = item;

  const normalize = (text: string | null | undefined = "") =>
    text?.toLowerCase().replace(/[`‘’“”"']/g, "'") || "";

  const textToSearch = [headline, summary, articleUrl]
    .flat()
    .map(normalize)
    .join(" ");

  const articleMentionsText = articleMentions
    ? articleMentions.map((mention) => normalize(mention.snippet)).join(" ")
    : "";

  const storyPublishersText = storyPublishers
    ? storyPublishers.map((publisher) => normalize(publisher.name)).join(" ")
    : "";

  const discoveredTagsText = discoveredTags
    ? discoveredTags.map((tag) => tag.tag).join(" ")
    : "";

  return `${textToSearch} ${articleMentionsText} ${storyPublishersText} ${discoveredTagsText}`;
}

function excludesTerms(item: Partial<FeedType>, excludeTerms: string[]) {
  const fullTextToSearch = processText(item);
  return excludeTerms.some((term) => fullTextToSearch.includes(term));
}

export function matchesSearchTerms(
  item: Partial<FeedType>,
  searchTerms: string[],
) {
  const fullTextToSearch = processText(item);
  return searchTerms.every((term) => fullTextToSearch.includes(term));
}

export const matchesSelectedFilters = (
  item: Partial<FeedType>,
  categoryAppliedFilters: CategoryAppliedFilter,
): boolean => {
  // Iterate over all the category applied filters and check if the item matches
  return Object.values(categoryAppliedFilters).every((filterValues) => {
    const { selected, relationship, logic } = filterValues;
    if (!selected?.length || !logic) return true;

    if (relationship === "AND") {
      return selected.every((selection) => logic(item, selection));
    }
    return selected.some((selection) => logic(item, selection));
  });
};

export const initCategoryAppliedFilters = (
  filterParams: FilterMap = getFilterParamsFromUrl(),
) => {
  const filters: CategoryAppliedFilter = {
    "Quality Score": {
      selected: filterParams["Quality Score"] || [],
      relationship: "OR-ONLY",
      itemCounts: Array.from({ length: 10 }, (_, i): [string, number] => [
        `${90 - i * 10}-${100 - (i === 0 ? 0 : i * 10 + 1)}`,
        0,
      ]).reduce(
        (acc, [key, value]) => {
          acc[key] = value;
          return acc;
        },
        {} as Record<string, number>,
      ),
      sorted: false,
      logic: (item, selection) => rangeLogic(item.maxScore, selection),
    },
    "Term Prominence": {
      selected: filterParams["Term Prominence"] || [],
      relationship: "OR",
      itemCounts: {
        Headline: 0,
        Feature: 0,
        Lede: 0,
        "Passing Mention": 0,
      },
      sorted: false,
      logic: (item, selection) =>
        (item.prominence ?? []).some(
          (prominence) => getPrettyName(prominence) === selection,
        ),
    },
    "Publication Tier": {
      selected: filterParams["Publication Tier"] || [],
      relationship: "OR-ONLY",
      itemCounts: {
        [TIER_1]: 0,
        [TIER_2]: 0,
        [TIER_3]: 0,
      },
      sorted: false,
      logic: (item, selection) => publicationTierLogic(item, selection),
    },
    "Social Engagement": {
      selected: filterParams["Social Engagement"] || [],
      relationship: "OR-ONLY",
      itemCounts: {
        "10,000+": 0,
        "5,000-9,999": 0,
        "3,000-4,999": 0,
        "1,000-2,999": 0,
        "500-999": 0,
        "100-499": 0,
        "1-99": 0,
        "0": 0,
      },
      sorted: false,
      logic: (item, selection) => {
        const engagement = item.maxSocial;
        if (engagement === undefined || engagement === null) return false;

        if (selection === "10,000+" && engagement >= 10000) return true;
        if (
          selection === "5,000-9,999" &&
          engagement >= 5000 &&
          engagement < 10000
        )
          return true;
        if (
          selection === "3,000-4,999" &&
          engagement >= 3000 &&
          engagement < 5000
        )
          return true;
        if (
          selection === "1,000-2,999" &&
          engagement >= 1000 &&
          engagement < 3000
        )
          return true;
        if (selection === "500-999" && engagement >= 500 && engagement < 1000)
          return true;
        if (selection === "100-499" && engagement >= 100 && engagement < 500)
          return true;
        if (selection === "1-99" && engagement >= 1 && engagement < 100)
          return true;
        if (selection === "0" && engagement === 0) return true;

        return false;
      },
    },
    "Domain Authority": {
      selected: filterParams["Domain Authority"] || [],
      relationship: "OR-ONLY",
      itemCounts: Array.from({ length: 10 }, (_, i): [string, number] => [
        `${90 - i * 10}-${100 - (i === 0 ? 0 : i * 10 + 1)}`,
        0,
      ]).reduce(
        (acc, [key, value]) => {
          acc[key] = value;
          return acc;
        },
        {} as Record<string, number>,
      ),
      sorted: false,
      logic: (item, selection) =>
        rangeLogic(item.maxDomainAuthority, selection),
    },
    "Content Category": {
      selected: filterParams["Content Category"] || [],
      relationship: "OR-ONLY",
      itemCounts: {},
      logic: (item, selection) => {
        const lowerCaseSelection = getPrettyName(selection.toLowerCase());
        const category = getPrettyName(
          item.articleNewsCategory?.toLowerCase() ?? "",
        );
        return lowerCaseSelection.toLowerCase() === category.toLowerCase();
      },
    },
    "Top Topics": {
      selected: filterParams["Top Topics"] || [],
      relationship: "OR",
      itemCounts: {},
      logic: (item, selection) =>
        (item.knownTags ?? []).some((tag: string) => tag === selection),
    },
    Publications: {
      selected: filterParams.Publications || [],
      relationship: "OR-ONLY",
      itemCounts: {},
      logic: publisherLogic,
    },
    Authors: {
      selected: filterParams.Authors || [],
      relationship: "OR-ONLY",
      itemCounts: {},
      logic: authorLogic,
    },
  };

  return filters;
};

export const publicationTierLogic = (
  item: Partial<FeedWithAugTypes>,
  selection: string,
): boolean => {
  const tier = item.tierLevel;
  if (!tier) return false;

  switch (selection) {
    case TIER_1:
      return tier === TIER_1;
    case TIER_2:
      return tier === TIER_2;
    case TIER_3:
      return tier === TIER_3;
    default:
      return false;
  }
};

export const updateCountsForCoverageTiers = (
  feedItem: Partial<FeedWithAugTypes>,
  coverageTier: CategoryFilter,
) => {
  const tierLevel = feedItem.tierLevel;
  if (!tierLevel) return;

  updateItemCount(coverageTier, tierLevel);
};

export const updateCountsForPublisher = (
  feedItem: Partial<FeedType>,
  publisherFilter: CategoryFilter,
) => {
  const publisher = feedItem.articlePublisher?.name;
  const storyPublishers = feedItem.storyPublishers;
  if (!publisher && !storyPublishers?.length) return;

  if (publisher) updateItemCount(publisherFilter, publisher);
  if (storyPublishers) {
    for (const { name } of storyPublishers) {
      updateItemCount(publisherFilter, name);
    }
  }
};

export const updateCountsForAuthors = (
  feedItem: Partial<FeedType>,
  authorFilter: CategoryFilter,
) => {
  if (!feedItem.articleAuthors?.length) return;
  const authors = feedItem.articleAuthors;

  if (!authorFilter) {
    return;
  }

  for (const item of authors) {
    const { name } = item;
    updateItemCount(authorFilter, name);
  }
};

export const updateCountsForCategory = (
  FeedItem: Partial<FeedType>,
  categoryFilter: CategoryFilter,
) => {
  if (!FeedItem.articleNewsCategory?.length) return;
  const articleCategory = getPrettyName(FeedItem.articleNewsCategory);

  if (!categoryFilter) {
    return;
  }

  updateItemCount(categoryFilter, articleCategory);
};

export const updateCountsForArticleMentions = (
  feedItem: Partial<FeedType>,
  termProminence: CategoryFilter,
) => {
  if (!feedItem.prominence?.length) return;

  if (termProminence) {
    for (const prominence of feedItem.prominence) {
      const prettyProminence = getPrettyName(prominence);
      updateItemCount(termProminence, prettyProminence);
    }
  }
};

export const updateCountsForTopTopics = (
  feedItem: Partial<FeedType>,
  topTopics: CategoryFilter,
  knownTags: string[],
) => {
  if (!feedItem.knownTags) return;

  const feedKnownTags = feedItem?.knownTags;

  if (!topTopics) return;

  for (const tag of knownTags) {
    updateItemCount(topTopics, tag, 0);
  }

  for (const tag of feedKnownTags) {
    updateItemCount(topTopics, tag);
  }
};

export const updateCountsForDiscoveredTags = (
  feedItem: Partial<FeedType>,
  categoryAppliedFilters: CategoryAppliedFilter,
  tagKindToUpdate: string | false = false,
) => {
  if (!feedItem.discoveredTags) return;

  for (const tag of feedItem.discoveredTags) {
    const tagKind = getPrettyName(tag.tagKind);
    if (tagKindToUpdate && tagKind !== tagKindToUpdate) continue;
    let categoryFilter = categoryAppliedFilters[tagKind];

    if (!categoryFilter) {
      categoryFilter = {
        relationship: "OR",
        selected: [],
        itemCounts: {},
        logic: (item, selection) => {
          return (item.discoveredTags ?? []).some(
            (itemTag) =>
              getPrettyName(itemTag.tagKind) === tagKind &&
              itemTag.tag === selection,
          );
        },
      };
      categoryAppliedFilters[tagKind] = categoryFilter;
    }

    updateItemCount(categoryFilter, tag.tag);
  }
};

export function publisherLogic(
  item: Partial<FeedType>,
  selection: string,
): boolean {
  const lowerCaseSelection = selection.toLowerCase();
  const matchesName =
    item.articlePublisher?.name.toLowerCase() === lowerCaseSelection;
  const matchesStoryPublishers =
    item.storyPublishers?.some(
      (publisher) => publisher?.name.toLowerCase() === lowerCaseSelection,
    ) ?? false;
  return matchesName || matchesStoryPublishers;
}

export function authorLogic(
  item: Partial<FeedType>,
  selection: string,
): boolean {
  const lowerCaseSelection = selection.toLowerCase();
  const authorNames =
    item.articleAuthors?.map((item) => item.name.toLowerCase()) ?? [];
  const matchesName = authorNames.includes(lowerCaseSelection);
  return matchesName;
}

export const updateCountsForDomainAuthority = (
  feedItem: Partial<FeedType>,
  domainAuthorityFilter: CategoryFilter,
) => {
  const authority = feedItem.maxDomainAuthority;
  if (authority === undefined || authority === null) return;

  if (!domainAuthorityFilter) return;

  const authorityLevel = getRangeLevel(authority, 10);
  updateItemCount(domainAuthorityFilter, authorityLevel);
};

export const updateCountsForQualityScore = (
  feedItem: Partial<FeedType>,
  qualityScoreFilter: CategoryFilter,
) => {
  const score = feedItem.maxScore;
  if (score === undefined || score === null) return;

  if (!qualityScoreFilter) return;

  const scoreLevel = getRangeLevel(score, 10);
  updateItemCount(qualityScoreFilter, scoreLevel);
};

export const updateCountsForSocialEngagement = (
  feedItem: Partial<FeedType>,
  socialEngagementFilter: CategoryFilter,
) => {
  const engagement = feedItem.maxSocial;
  if (engagement === undefined || engagement === null) return;

  if (!socialEngagementFilter) return;

  const engagementLevels = [
    { min: 10000, label: "10,000+" },
    { min: 5000, label: "5,000-9,999" },
    { min: 3000, label: "3,000-4,999" },
    { min: 1000, label: "1,000-2,999" },
    { min: 500, label: "500-999" },
    { min: 100, label: "100-499" },
    { min: 1, label: "1-99" },
    { min: 0, label: "0" },
  ];

  const engagementLevel =
    engagementLevels.find((level) => engagement >= level.min)?.label || "0";

  updateItemCount(socialEngagementFilter, engagementLevel);
};

const getRangeLevel = (value: number, increment = 10) => {
  if (value >= 90) {
    return "90-100";
  }

  const lowerBound = Math.floor(value / increment) * increment;
  return `${lowerBound}-${lowerBound + increment - 1}`;
};

const rangeLogic = (
  itemValue: number | undefined,
  selection: string,
): boolean => {
  if (itemValue === undefined || itemValue === null) return false;
  const [min, max] = selection.split("-").map(Number);
  if (typeof min === "undefined" || typeof max === "undefined") return false;
  return itemValue >= min && itemValue <= max;
};

export function clearExistingCounts(
  categoryKeyToIgnore: string | false,
  updatedCategoryAppliedFilters: CategoryAppliedFilter,
) {
  for (const [key, filter] of Object.entries(updatedCategoryAppliedFilters)) {
    if (!filter || !filter.itemCounts) continue;
    if (categoryKeyToIgnore && key === categoryKeyToIgnore) continue;
    for (const category in filter.itemCounts) {
      filter.itemCounts[category] = 0;
      updatedCategoryAppliedFilters[key] = filter;
    }
  }
}

export function calculateNewCounts(
  updatedCategoryAppliedFilters: CategoryAppliedFilter,
  categoryKeyToIgnore: string | false,
  filteredFeedItems: Partial<FeedWithAugTypes>[],
  knownTags: string[],
) {
  for (const feedItem of filteredFeedItems) {
    if (!categoryKeyToIgnore)
      updateCountsForDiscoveredTags(feedItem, updatedCategoryAppliedFilters);
    for (const key in updatedCategoryAppliedFilters) {
      const filter = updatedCategoryAppliedFilters[key];
      if (!filter) continue;
      if (!filter.itemCounts) filter.itemCounts = {};
      const totalFilterCount = Object.values(filter.itemCounts).reduce(
        (acc, currentValue) => acc + currentValue,
        0,
      );
      const shouldCount = categoryKeyToIgnore !== key || !totalFilterCount;
      switch (key) {
        case "Term Prominence":
          if (shouldCount) updateCountsForArticleMentions(feedItem, filter);
          break;
        case "Publication Tier":
          if (shouldCount) updateCountsForCoverageTiers(feedItem, filter);
          break;
        case "Publications":
          if (shouldCount) updateCountsForPublisher(feedItem, filter);
          break;
        case "Authors":
          if (shouldCount) updateCountsForAuthors(feedItem, filter);
          break;
        case "Content Category":
          if (shouldCount) updateCountsForCategory(feedItem, filter);
          break;
        case "Top Topics":
          if (shouldCount)
            updateCountsForTopTopics(feedItem, filter, knownTags);
          break;
        case "Domain Authority":
          if (shouldCount) updateCountsForDomainAuthority(feedItem, filter);
          break;
        case "Quality Score":
          if (shouldCount) updateCountsForQualityScore(feedItem, filter);
          break;
        case "Social Engagement":
          if (shouldCount) updateCountsForSocialEngagement(feedItem, filter);
          break;
        default:
          if (shouldCount)
            updateCountsForDiscoveredTags(
              feedItem,
              updatedCategoryAppliedFilters,
              key,
            );
          break;
      }
    }
  }
}

export function updateAppliedFiltersToUrl(
  categoryAppliedFilters: CategoryAppliedFilter,
) {
  const appliedFilters = Object.entries(categoryAppliedFilters).reduce(
    (acc: string[], [key, value]) => {
      if (value.selected && value.selected.length > 0) {
        acc.push(`${key}:${value.selected.join(",")}`);
      }
      return acc;
    },
    [],
  );
  setFilterParamsToUrl(appliedFilters.join(";"));
}

export const updateItemCount = (
  filter: CategoryAppliedFilter[keyof CategoryAppliedFilter] | undefined,
  key: string,
  increment = 1,
) => {
  if (!filter || !key) return;
  if (!filter.itemCounts) filter.itemCounts = {};
  filter.itemCounts[key] = (filter.itemCounts[key] ?? 0) + increment;
};
