import { standardAuthFetch } from "@/data-access/core/authenticatedFetch";
import { dataServiceBaseUrl } from "@/data-access/core/constants";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const emptySplitApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${dataServiceBaseUrl}/`,
    fetchFn: standardAuthFetch,
  }),
  endpoints: () => ({}),
});
