import { emptySplitApi as api } from "../emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    campaignsList: build.query<CampaignsListApiResponse, CampaignsListApiArg>({
      query: (queryArg) => ({
        url: `/api/campaigns/`,
        headers: { "X-NA-TENANT": queryArg["X-NA-TENANT"] },
        params: {
          group: queryArg.group,
          name: queryArg.name,
          order: queryArg.order,
          page: queryArg.page,
          search: queryArg.search,
          status: queryArg.status,
        },
      }),
    }),
    campaignsCreate: build.mutation<
      CampaignsCreateApiResponse,
      CampaignsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/campaigns/`,
        method: "POST",
        body: queryArg.campaign,
        headers: { "X-NA-TENANT": queryArg["X-NA-TENANT"] },
      }),
    }),
    campaignsRetrieve: build.query<
      CampaignsRetrieveApiResponse,
      CampaignsRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/campaigns/${queryArg.id}/`,
        headers: { "X-NA-TENANT": queryArg["X-NA-TENANT"] },
      }),
    }),
    campaignsUpdate: build.mutation<
      CampaignsUpdateApiResponse,
      CampaignsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/campaigns/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.campaign,
        headers: { "X-NA-TENANT": queryArg["X-NA-TENANT"] },
      }),
    }),
    campaignsPartialUpdate: build.mutation<
      CampaignsPartialUpdateApiResponse,
      CampaignsPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/campaigns/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedCampaign,
        headers: { "X-NA-TENANT": queryArg["X-NA-TENANT"] },
      }),
    }),
    campaignsDestroy: build.mutation<
      CampaignsDestroyApiResponse,
      CampaignsDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/campaigns/${queryArg.id}/`,
        method: "DELETE",
        headers: { "X-NA-TENANT": queryArg["X-NA-TENANT"] },
      }),
    }),
    campaignsAnalyticsRetrieve: build.query<
      CampaignsAnalyticsRetrieveApiResponse,
      CampaignsAnalyticsRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/campaigns/${queryArg.id}/analytics/`,
        headers: { "X-NA-TENANT": queryArg["X-NA-TENANT"] },
      }),
    }),
    campaignsDemographicsList: build.query<
      CampaignsDemographicsListApiResponse,
      CampaignsDemographicsListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/campaigns/${queryArg.id}/demographics/`,
        headers: { "X-NA-TENANT": queryArg["X-NA-TENANT"] },
      }),
    }),
    campaignsDuplicateCreate: build.mutation<
      CampaignsDuplicateCreateApiResponse,
      CampaignsDuplicateCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/campaigns/${queryArg.id}/duplicate/`,
        method: "POST",
        body: queryArg.campaign,
        headers: { "X-NA-TENANT": queryArg["X-NA-TENANT"] },
      }),
    }),
    campaignsAdRedirectRetrieve: build.query<
      CampaignsAdRedirectRetrieveApiResponse,
      CampaignsAdRedirectRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/campaigns/ad-redirect/${queryArg.shortUrl}/`,
      }),
    }),
    campaignsAdsList: build.query<
      CampaignsAdsListApiResponse,
      CampaignsAdsListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/campaigns/ads/`,
        headers: { "X-NA-TENANT": queryArg["X-NA-TENANT"] },
        params: { page: queryArg.page, search: queryArg.search },
      }),
    }),
    campaignsAdsCreate: build.mutation<
      CampaignsAdsCreateApiResponse,
      CampaignsAdsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/campaigns/ads/`,
        method: "POST",
        body: queryArg.ad,
        headers: { "X-NA-TENANT": queryArg["X-NA-TENANT"] },
      }),
    }),
    campaignsAdsRetrieve: build.query<
      CampaignsAdsRetrieveApiResponse,
      CampaignsAdsRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/campaigns/ads/${queryArg.id}/`,
        headers: { "X-NA-TENANT": queryArg["X-NA-TENANT"] },
      }),
    }),
    campaignsAdsUpdate: build.mutation<
      CampaignsAdsUpdateApiResponse,
      CampaignsAdsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/campaigns/ads/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.ad,
        headers: { "X-NA-TENANT": queryArg["X-NA-TENANT"] },
      }),
    }),
    campaignsAdsPartialUpdate: build.mutation<
      CampaignsAdsPartialUpdateApiResponse,
      CampaignsAdsPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/campaigns/ads/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedAd,
        headers: { "X-NA-TENANT": queryArg["X-NA-TENANT"] },
      }),
    }),
    campaignsAdsDestroy: build.mutation<
      CampaignsAdsDestroyApiResponse,
      CampaignsAdsDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/campaigns/ads/${queryArg.id}/`,
        method: "DELETE",
        headers: { "X-NA-TENANT": queryArg["X-NA-TENANT"] },
      }),
    }),
    campaignsAdsAnalyticsRetrieve: build.query<
      CampaignsAdsAnalyticsRetrieveApiResponse,
      CampaignsAdsAnalyticsRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/campaigns/ads/${queryArg.id}/analytics/`,
        headers: { "X-NA-TENANT": queryArg["X-NA-TENANT"] },
      }),
    }),
    campaignsAdsCommentsList: build.query<
      CampaignsAdsCommentsListApiResponse,
      CampaignsAdsCommentsListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/campaigns/ads/${queryArg.id}/comments/`,
        headers: { "X-NA-TENANT": queryArg["X-NA-TENANT"] },
      }),
    }),
    campaignsAdsDemographicsList: build.query<
      CampaignsAdsDemographicsListApiResponse,
      CampaignsAdsDemographicsListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/campaigns/ads/${queryArg.id}/demographics/`,
        headers: { "X-NA-TENANT": queryArg["X-NA-TENANT"] },
      }),
    }),
    campaignsAdsLandingpageCreate: build.mutation<
      CampaignsAdsLandingpageCreateApiResponse,
      CampaignsAdsLandingpageCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/campaigns/ads/${queryArg.id}/landingpage/`,
        method: "POST",
        body: queryArg.adLandingPage,
        headers: { "X-NA-TENANT": queryArg["X-NA-TENANT"] },
      }),
    }),
    campaignsAdsImagePreviewRetrieve: build.query<
      CampaignsAdsImagePreviewRetrieveApiResponse,
      CampaignsAdsImagePreviewRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/campaigns/ads/image-preview/`,
        params: {
          height: queryArg.height,
          image: queryArg.image,
          logo: queryArg.logo,
          width: queryArg.width,
        },
      }),
    }),
    campaignsAudiencesList: build.query<
      CampaignsAudiencesListApiResponse,
      CampaignsAudiencesListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/campaigns/audiences/`,
        headers: { "X-NA-TENANT": queryArg["X-NA-TENANT"] },
        params: { page: queryArg.page, search: queryArg.search },
      }),
    }),
    campaignsAudiencesCreate: build.mutation<
      CampaignsAudiencesCreateApiResponse,
      CampaignsAudiencesCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/campaigns/audiences/`,
        method: "POST",
        body: queryArg.audience,
        headers: { "X-NA-TENANT": queryArg["X-NA-TENANT"] },
      }),
    }),
    campaignsAudiencesRetrieve: build.query<
      CampaignsAudiencesRetrieveApiResponse,
      CampaignsAudiencesRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/campaigns/audiences/${queryArg.id}/`,
        headers: { "X-NA-TENANT": queryArg["X-NA-TENANT"] },
      }),
    }),
    campaignsAudiencesUpdate: build.mutation<
      CampaignsAudiencesUpdateApiResponse,
      CampaignsAudiencesUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/campaigns/audiences/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.audience,
        headers: { "X-NA-TENANT": queryArg["X-NA-TENANT"] },
      }),
    }),
    campaignsAudiencesPartialUpdate: build.mutation<
      CampaignsAudiencesPartialUpdateApiResponse,
      CampaignsAudiencesPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/campaigns/audiences/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedAudience,
        headers: { "X-NA-TENANT": queryArg["X-NA-TENANT"] },
      }),
    }),
    campaignsAudiencesDestroy: build.mutation<
      CampaignsAudiencesDestroyApiResponse,
      CampaignsAudiencesDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/campaigns/audiences/${queryArg.id}/`,
        method: "DELETE",
        headers: { "X-NA-TENANT": queryArg["X-NA-TENANT"] },
      }),
    }),
    campaignsAudiencesFacetsList: build.query<
      CampaignsAudiencesFacetsListApiResponse,
      CampaignsAudiencesFacetsListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/campaigns/audiences/facets/`,
        params: { page: queryArg.page, search: queryArg.search },
      }),
    }),
    campaignsAudiencesFacetsEntitiesList: build.query<
      CampaignsAudiencesFacetsEntitiesListApiResponse,
      CampaignsAudiencesFacetsEntitiesListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/campaigns/audiences/facets/${queryArg.facetId}/entities/`,
        params: { page: queryArg.page, search: queryArg.search },
      }),
    }),
    campaignsAudiencesFacetsEntitiesRetrieve: build.query<
      CampaignsAudiencesFacetsEntitiesRetrieveApiResponse,
      CampaignsAudiencesFacetsEntitiesRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/campaigns/audiences/facets/${queryArg.facetId}/entities/${queryArg.id}/`,
      }),
    }),
    campaignsAudiencesFacetsRetrieve: build.query<
      CampaignsAudiencesFacetsRetrieveApiResponse,
      CampaignsAudiencesFacetsRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/campaigns/audiences/facets/${queryArg.id}/`,
      }),
    }),
    campaignsAudiencesGenerateCreate: build.mutation<
      CampaignsAudiencesGenerateCreateApiResponse,
      CampaignsAudiencesGenerateCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/campaigns/audiences/generate/`,
        method: "POST",
        body: queryArg.audienceGenerationInput,
        headers: { "X-NA-TENANT": queryArg["X-NA-TENANT"] },
      }),
    }),
    campaignsGroupsList: build.query<
      CampaignsGroupsListApiResponse,
      CampaignsGroupsListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/campaigns/groups/`,
        headers: { "X-NA-TENANT": queryArg["X-NA-TENANT"] },
        params: { page: queryArg.page, search: queryArg.search },
      }),
    }),
    campaignsGroupsCreate: build.mutation<
      CampaignsGroupsCreateApiResponse,
      CampaignsGroupsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/campaigns/groups/`,
        method: "POST",
        body: queryArg.campaignGroup,
        headers: { "X-NA-TENANT": queryArg["X-NA-TENANT"] },
      }),
    }),
    campaignsGroupsRetrieve: build.query<
      CampaignsGroupsRetrieveApiResponse,
      CampaignsGroupsRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/campaigns/groups/${queryArg.id}/`,
        headers: { "X-NA-TENANT": queryArg["X-NA-TENANT"] },
      }),
    }),
    campaignsGroupsUpdate: build.mutation<
      CampaignsGroupsUpdateApiResponse,
      CampaignsGroupsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/campaigns/groups/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.campaignGroup,
        headers: { "X-NA-TENANT": queryArg["X-NA-TENANT"] },
      }),
    }),
    campaignsGroupsPartialUpdate: build.mutation<
      CampaignsGroupsPartialUpdateApiResponse,
      CampaignsGroupsPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/campaigns/groups/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedCampaignGroup,
        headers: { "X-NA-TENANT": queryArg["X-NA-TENANT"] },
      }),
    }),
    campaignsGroupsDestroy: build.mutation<
      CampaignsGroupsDestroyApiResponse,
      CampaignsGroupsDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/campaigns/groups/${queryArg.id}/`,
        method: "DELETE",
        headers: { "X-NA-TENANT": queryArg["X-NA-TENANT"] },
      }),
    }),
    campaignsTrackCreate: build.mutation<
      CampaignsTrackCreateApiResponse,
      CampaignsTrackCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/campaigns/track/`,
        method: "POST",
        body: queryArg.eventTracker,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as campaignApi };
export type CampaignsListApiResponse =
  /** status 200  */ PaginatedCampaignListRead;
export type CampaignsListApiArg = {
  /** Use this header to perform actions in specific Tenant */
  "X-NA-TENANT"?: string;
  group?: number;
  name?: string;
  /** Ordering
    
    * `name` - Campaign Name
    * `-name` - Campaign Name (descending)
    * `created_at` - Created Date
    * `-created_at` - Created Date (descending)
    * `updated_at` - Updated Date
    * `-updated_at` - Updated Date (descending)
    * `start_date` - Campaign Start Date
    * `-start_date` - Campaign Start Date (descending)
    * `end_date` - Campaign End Date
    * `-end_date` - Campaign End Date (descending)
    * `status` - Campaign Status
    * `-status` - Campaign Status (descending)
    * `total_budget` - Total Budget
    * `-total_budget` - Total Budget (descending)
    * `daily_budget` - Daily Budget
    * `-daily_budget` - Daily Budget (descending)
    * `channel` - Campaign Channel
    * `-channel` - Campaign Channel (descending)
    * `owner_name` - Campaign Owner Name
    * `-owner_name` - Campaign Owner Name (descending)
    * `spend` - Metrics Spend
    * `-spend` - Metrics Spend (descending)
    * `impressions` - Metrics Impressions
    * `-impressions` - Metrics Impressions (descending)
    * `clicks` - Metrics Clicks
    * `-clicks` - Metrics Clicks (descending)
    * `ctr` - Metrics CTR
    * `-ctr` - Metrics CTR (descending)
    * `cpr` - Metrics CPR
    * `-cpr` - Metrics CPR (descending)
    * `reactions` - Metrics Reactions
    * `-reactions` - Metrics Reactions (descending)
    * `shares` - Metrics Shares
    * `-shares` - Metrics Shares (descending)
    * `comments` - Metrics Comments
    * `-comments` - Metrics Comments (descending)
    * `follows` - Metrics Follows
    * `-follows` - Metrics Follows (descending) */
  order?: (
    | "-channel"
    | "-clicks"
    | "-comments"
    | "-cpr"
    | "-created_at"
    | "-ctr"
    | "-daily_budget"
    | "-end_date"
    | "-follows"
    | "-impressions"
    | "-name"
    | "-owner_name"
    | "-reactions"
    | "-shares"
    | "-spend"
    | "-start_date"
    | "-status"
    | "-total_budget"
    | "-updated_at"
    | "channel"
    | "clicks"
    | "comments"
    | "cpr"
    | "created_at"
    | "ctr"
    | "daily_budget"
    | "end_date"
    | "follows"
    | "impressions"
    | "name"
    | "owner_name"
    | "reactions"
    | "shares"
    | "spend"
    | "start_date"
    | "status"
    | "total_budget"
    | "updated_at"
  )[];
  /** A page number within the paginated result set. */
  page?: number;
  /** A search term. */
  search?: string;
  /** * `1` - Draft
    * `2` - Active
    * `3` - Archived
    * `4` - Paused
    * `5` - Completed
    * `6` - Ready to Publish
    
    * `1` - Draft
    * `2` - Active
    * `3` - Archived
    * `4` - Paused
    * `5` - Completed
    * `6` - Ready to Publish */
  status?: 1 | 2 | 3 | 4 | 5 | 6;
};
export type CampaignsCreateApiResponse = /** status 201  */ CampaignRead;
export type CampaignsCreateApiArg = {
  /** Use this header to perform actions in specific Tenant */
  "X-NA-TENANT"?: string;
  campaign: Campaign;
};
export type CampaignsRetrieveApiResponse = /** status 200  */ CampaignRead;
export type CampaignsRetrieveApiArg = {
  /** Use this header to perform actions in specific Tenant */
  "X-NA-TENANT"?: string;
  /** A unique integer value identifying this Campaign. */
  id: number;
};
export type CampaignsUpdateApiResponse = /** status 200  */ CampaignRead;
export type CampaignsUpdateApiArg = {
  /** Use this header to perform actions in specific Tenant */
  "X-NA-TENANT"?: string;
  /** A unique integer value identifying this Campaign. */
  id: number;
  campaign: Campaign;
};
export type CampaignsPartialUpdateApiResponse = /** status 200  */ CampaignRead;
export type CampaignsPartialUpdateApiArg = {
  /** Use this header to perform actions in specific Tenant */
  "X-NA-TENANT"?: string;
  /** A unique integer value identifying this Campaign. */
  id: number;
  patchedCampaign: PatchedCampaign;
};
export type CampaignsDestroyApiResponse = unknown;
export type CampaignsDestroyApiArg = {
  /** Use this header to perform actions in specific Tenant */
  "X-NA-TENANT"?: string;
  /** A unique integer value identifying this Campaign. */
  id: number;
};
export type CampaignsAnalyticsRetrieveApiResponse =
  /** status 200  */ Analytics;
export type CampaignsAnalyticsRetrieveApiArg = {
  /** Use this header to perform actions in specific Tenant */
  "X-NA-TENANT"?: string;
  /** A unique integer value identifying this Campaign. */
  id: number;
};
export type CampaignsDemographicsListApiResponse =
  /** status 200  */ Demographics[];
export type CampaignsDemographicsListApiArg = {
  /** Use this header to perform actions in specific Tenant */
  "X-NA-TENANT"?: string;
  /** A unique integer value identifying this Campaign. */
  id: number;
};
export type CampaignsDuplicateCreateApiResponse =
  /** status 200  */ CampaignRead;
export type CampaignsDuplicateCreateApiArg = {
  /** Use this header to perform actions in specific Tenant */
  "X-NA-TENANT"?: string;
  /** A unique integer value identifying this Campaign. */
  id: number;
  campaign: Campaign;
};
export type CampaignsAdRedirectRetrieveApiResponse =
  /** status 200  */ AdPreviewRead;
export type CampaignsAdRedirectRetrieveApiArg = {
  shortUrl: string;
};
export type CampaignsAdsListApiResponse =
  /** status 200  */ PaginatedAdListRead;
export type CampaignsAdsListApiArg = {
  /** Use this header to perform actions in specific Tenant */
  "X-NA-TENANT"?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** A search term. */
  search?: string;
};
export type CampaignsAdsCreateApiResponse = /** status 201  */ AdRead;
export type CampaignsAdsCreateApiArg = {
  /** Use this header to perform actions in specific Tenant */
  "X-NA-TENANT"?: string;
  ad: Ad;
};
export type CampaignsAdsRetrieveApiResponse = /** status 200  */ AdRead;
export type CampaignsAdsRetrieveApiArg = {
  /** Use this header to perform actions in specific Tenant */
  "X-NA-TENANT"?: string;
  /** A unique integer value identifying this Ad. */
  id: number;
};
export type CampaignsAdsUpdateApiResponse = /** status 200  */ AdRead;
export type CampaignsAdsUpdateApiArg = {
  /** Use this header to perform actions in specific Tenant */
  "X-NA-TENANT"?: string;
  /** A unique integer value identifying this Ad. */
  id: number;
  ad: Ad;
};
export type CampaignsAdsPartialUpdateApiResponse = /** status 200  */ AdRead;
export type CampaignsAdsPartialUpdateApiArg = {
  /** Use this header to perform actions in specific Tenant */
  "X-NA-TENANT"?: string;
  /** A unique integer value identifying this Ad. */
  id: number;
  patchedAd: PatchedAd;
};
export type CampaignsAdsDestroyApiResponse = unknown;
export type CampaignsAdsDestroyApiArg = {
  /** Use this header to perform actions in specific Tenant */
  "X-NA-TENANT"?: string;
  /** A unique integer value identifying this Ad. */
  id: number;
};
export type CampaignsAdsAnalyticsRetrieveApiResponse =
  /** status 200  */ Analytics;
export type CampaignsAdsAnalyticsRetrieveApiArg = {
  /** Use this header to perform actions in specific Tenant */
  "X-NA-TENANT"?: string;
  /** A unique integer value identifying this Ad. */
  id: number;
};
export type CampaignsAdsCommentsListApiResponse =
  /** status 200  */ AdCommentsRead[];
export type CampaignsAdsCommentsListApiArg = {
  /** Use this header to perform actions in specific Tenant */
  "X-NA-TENANT"?: string;
  /** A unique integer value identifying this Ad. */
  id: number;
};
export type CampaignsAdsDemographicsListApiResponse =
  /** status 200  */ Demographics[];
export type CampaignsAdsDemographicsListApiArg = {
  /** Use this header to perform actions in specific Tenant */
  "X-NA-TENANT"?: string;
  /** A unique integer value identifying this Ad. */
  id: number;
};
export type CampaignsAdsLandingpageCreateApiResponse =
  /** status 200 The updated Ad landing page configuration. */ AdLandingPage;
export type CampaignsAdsLandingpageCreateApiArg = {
  /** Use this header to perform actions in specific Tenant */
  "X-NA-TENANT"?: string;
  /** A unique integer value identifying this Ad. */
  id: number;
  adLandingPage: AdLandingPage;
};
export type CampaignsAdsImagePreviewRetrieveApiResponse =
  /** status 200 The generated image */ {
    [key: string]: any;
  };
export type CampaignsAdsImagePreviewRetrieveApiArg = {
  /** Height of the generated image in pixels. */
  height?: number;
  /** ArticleImage ID to use as background */
  image: number;
  /** PublisherLogo ID */
  logo?: number | null;
  /** Width of the generated image in pixels. */
  width?: number;
};
export type CampaignsAudiencesListApiResponse =
  /** status 200  */ PaginatedAudienceListRead;
export type CampaignsAudiencesListApiArg = {
  /** Use this header to perform actions in specific Tenant */
  "X-NA-TENANT"?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** A search term. */
  search?: string;
};
export type CampaignsAudiencesCreateApiResponse =
  /** status 201  */ AudienceRead;
export type CampaignsAudiencesCreateApiArg = {
  /** Use this header to perform actions in specific Tenant */
  "X-NA-TENANT"?: string;
  audience: AudienceWrite;
};
export type CampaignsAudiencesRetrieveApiResponse =
  /** status 200  */ AudienceRead;
export type CampaignsAudiencesRetrieveApiArg = {
  /** Use this header to perform actions in specific Tenant */
  "X-NA-TENANT"?: string;
  /** A unique integer value identifying this Audience. */
  id: number;
};
export type CampaignsAudiencesUpdateApiResponse =
  /** status 200  */ AudienceRead;
export type CampaignsAudiencesUpdateApiArg = {
  /** Use this header to perform actions in specific Tenant */
  "X-NA-TENANT"?: string;
  /** A unique integer value identifying this Audience. */
  id: number;
  audience: AudienceWrite;
};
export type CampaignsAudiencesPartialUpdateApiResponse =
  /** status 200  */ AudienceRead;
export type CampaignsAudiencesPartialUpdateApiArg = {
  /** Use this header to perform actions in specific Tenant */
  "X-NA-TENANT"?: string;
  /** A unique integer value identifying this Audience. */
  id: number;
  patchedAudience: PatchedAudienceWrite;
};
export type CampaignsAudiencesDestroyApiResponse = unknown;
export type CampaignsAudiencesDestroyApiArg = {
  /** Use this header to perform actions in specific Tenant */
  "X-NA-TENANT"?: string;
  /** A unique integer value identifying this Audience. */
  id: number;
};
export type CampaignsAudiencesFacetsListApiResponse =
  /** status 200  */ PaginatedAudienceFacetListRead;
export type CampaignsAudiencesFacetsListApiArg = {
  /** A page number within the paginated result set. */
  page?: number;
  /** A search term. */
  search?: string;
};
export type CampaignsAudiencesFacetsEntitiesListApiResponse =
  /** status 200  */ PaginatedAudienceEntityListRead;
export type CampaignsAudiencesFacetsEntitiesListApiArg = {
  facetId: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** A search term. */
  search?: string;
};
export type CampaignsAudiencesFacetsEntitiesRetrieveApiResponse =
  /** status 200  */ AudienceEntityRead;
export type CampaignsAudiencesFacetsEntitiesRetrieveApiArg = {
  facetId: string;
  /** A unique integer value identifying this Entity. */
  id: number;
};
export type CampaignsAudiencesFacetsRetrieveApiResponse =
  /** status 200  */ AudienceFacetRead;
export type CampaignsAudiencesFacetsRetrieveApiArg = {
  /** A unique integer value identifying this Facet. */
  id: number;
};
export type CampaignsAudiencesGenerateCreateApiResponse =
  /** status 200  */ AudienceRead;
export type CampaignsAudiencesGenerateCreateApiArg = {
  /** Use this header to perform actions in specific Tenant */
  "X-NA-TENANT"?: string;
  audienceGenerationInput: AudienceGenerationInput;
};
export type CampaignsGroupsListApiResponse =
  /** status 200  */ PaginatedCampaignGroupListRead;
export type CampaignsGroupsListApiArg = {
  /** Use this header to perform actions in specific Tenant */
  "X-NA-TENANT"?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** A search term. */
  search?: string;
};
export type CampaignsGroupsCreateApiResponse =
  /** status 201  */ CampaignGroupRead;
export type CampaignsGroupsCreateApiArg = {
  /** Use this header to perform actions in specific Tenant */
  "X-NA-TENANT"?: string;
  campaignGroup: CampaignGroup;
};
export type CampaignsGroupsRetrieveApiResponse =
  /** status 200  */ CampaignGroupRead;
export type CampaignsGroupsRetrieveApiArg = {
  /** Use this header to perform actions in specific Tenant */
  "X-NA-TENANT"?: string;
  /** A unique integer value identifying this Campaign Group. */
  id: number;
};
export type CampaignsGroupsUpdateApiResponse =
  /** status 200  */ CampaignGroupRead;
export type CampaignsGroupsUpdateApiArg = {
  /** Use this header to perform actions in specific Tenant */
  "X-NA-TENANT"?: string;
  /** A unique integer value identifying this Campaign Group. */
  id: number;
  campaignGroup: CampaignGroup;
};
export type CampaignsGroupsPartialUpdateApiResponse =
  /** status 200  */ CampaignGroupRead;
export type CampaignsGroupsPartialUpdateApiArg = {
  /** Use this header to perform actions in specific Tenant */
  "X-NA-TENANT"?: string;
  /** A unique integer value identifying this Campaign Group. */
  id: number;
  patchedCampaignGroup: PatchedCampaignGroup;
};
export type CampaignsGroupsDestroyApiResponse = unknown;
export type CampaignsGroupsDestroyApiArg = {
  /** Use this header to perform actions in specific Tenant */
  "X-NA-TENANT"?: string;
  /** A unique integer value identifying this Campaign Group. */
  id: number;
};
export type CampaignsTrackCreateApiResponse = unknown;
export type CampaignsTrackCreateApiArg = {
  eventTracker: EventTracker;
};
export type StatusC58Enum = 1 | 2 | 3 | 4 | 5 | 6;
export type CurrencyEnum = "USD";
export type CampaignTypeEnum =
  | "awareness"
  | "traffic"
  | "engagement"
  | "leads"
  | "app_promotion"
  | "sales";
export type ChannelEnum =
  | "facebook"
  | "instagram"
  | "linkedin"
  | "tiktok"
  | "twitter";
export type Campaign = {
  name?: string;
  group?: number | null;
  owner?: number | null;
  status?: StatusC58Enum;
  start_date?: string;
  /** Set null for continuous campaign */
  end_date?: string | null;
  /** Total Budget */
  total_budget?: string | null;
  daily_budget?: string | null;
  currency?: CurrencyEnum;
  campaign_type?: CampaignTypeEnum;
  channel: ChannelEnum;
  audience?: number | null;
};
export type AdStatusEnum = 1 | 2 | 3 | 4 | 5;
export type Ad = {
  name?: string;
  campaign: number;
  article?: number | null;
  summary?: string;
  headline?: string;
  lead?: string;
  image?: number | null;
  logo?: number | null;
  status?: AdStatusEnum;
  /** Call to action link */
  utm_url?: string;
};
export type ButtonStyleEnum = "pill";
export type BlankEnum = "";
export type AdLandingPage = {
  active?: boolean;
  headline?: string;
  summary?: string;
  button_text?: string;
  /** Call to action link */
  utm_url?: string;
  background_color?: string;
  text_color?: string;
  button_style?: ButtonStyleEnum | BlankEnum;
  button_bg_color?: string;
  button_text_color?: string;
  include_publication_name?: boolean;
  include_author_name?: boolean;
};
export type AdRead = {
  id: number;
  name?: string;
  campaign: number;
  article?: number | null;
  summary?: string;
  headline?: string;
  lead?: string;
  image?: number | null;
  logo?: number | null;
  short_url: string;
  status?: AdStatusEnum;
  status_display: string;
  /** Call to action link */
  utm_url?: string;
  landing_page: AdLandingPage | null;
  updated_at: string;
};
export type CampaignMetric = {
  /** Budget Spent */
  spend?: string;
  impressions?: number;
  clicks?: number;
  /** Click-Through Rate */
  ctr: string;
  /** Cost Per Result */
  cpr: string;
  /** Cost per thousand */
  cpm?: string | null;
  reactions?: number;
  shares?: number;
  comments?: number;
  follows?: number;
};
export type CampaignRead = {
  id: number;
  tenant: number;
  name?: string;
  group?: number | null;
  owner?: number | null;
  status?: StatusC58Enum;
  status_display: string;
  start_date?: string;
  /** Set null for continuous campaign */
  end_date?: string | null;
  /** Total Budget */
  total_budget?: string | null;
  daily_budget?: string | null;
  currency?: CurrencyEnum;
  campaign_type?: CampaignTypeEnum;
  campaign_type_display: string;
  channel: ChannelEnum;
  channel_display: string;
  audience?: number | null;
  ads: AdRead[];
  owner_display: string;
  updated_at: string;
  metrics: CampaignMetric;
};
export type PaginatedCampaignList = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: Campaign[];
};
export type PaginatedCampaignListRead = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: CampaignRead[];
};
export type PatchedCampaign = {
  name?: string;
  group?: number | null;
  owner?: number | null;
  status?: StatusC58Enum;
  start_date?: string;
  /** Set null for continuous campaign */
  end_date?: string | null;
  /** Total Budget */
  total_budget?: string | null;
  daily_budget?: string | null;
  currency?: CurrencyEnum;
  campaign_type?: CampaignTypeEnum;
  channel?: ChannelEnum;
  audience?: number | null;
};
export type PatchedCampaignRead = {
  id?: number;
  tenant?: number;
  name?: string;
  group?: number | null;
  owner?: number | null;
  status?: StatusC58Enum;
  status_display?: string;
  start_date?: string;
  /** Set null for continuous campaign */
  end_date?: string | null;
  /** Total Budget */
  total_budget?: string | null;
  daily_budget?: string | null;
  currency?: CurrencyEnum;
  campaign_type?: CampaignTypeEnum;
  campaign_type_display?: string;
  channel?: ChannelEnum;
  channel_display?: string;
  audience?: number | null;
  ads?: AdRead[];
  owner_display?: string;
  updated_at?: string;
  metrics?: CampaignMetric;
};
export type Analytics = {
  avg_ctr: number;
  avg_cpr: number;
  total_spend: string;
  total_impressions: number;
  total_clicks: number;
  total_reactions: number;
  total_shares: number;
  total_comments: number;
  total_follows: number;
};
export type Demographics = {
  entity_name: string;
  entity_type: string;
  avg_ctr: number;
  avg_cpr: number;
  total_spend: string;
  total_impressions: number;
  total_clicks: number;
};
export type AdPreview = {
  headline?: string;
  logo: string | null;
  short_url: string;
  source_url: string;
  summary?: string;
  publication: string;
  tenant: string;
  tenant_slug: string;
  tenant_logo: string | null;
  authors: string[] | null;
  /** Call to action link */
  utm_url?: string;
  date_published: string | null;
};
export type ArticleImageRead = {
  article: number;
  caption?: string;
  is_main_image?: boolean;
};
export type ArticleImageReadRead = {
  id: number;
  article: number;
  image: string;
  caption?: string;
  is_main_image?: boolean;
};
export type ArticleImageReadWrite = {
  article: number;
  caption?: string;
  is_main_image?: boolean;
  source?: string;
};
export type AdPreviewRead = {
  headline?: string;
  image: ArticleImageReadRead | null;
  logo: string | null;
  short_url: string;
  source_url: string;
  summary?: string;
  publication: string;
  tenant: string;
  tenant_slug: string;
  tenant_logo: string | null;
  authors: string[] | null;
  /** Call to action link */
  utm_url?: string;
  date_published: string | null;
  landing_page: AdLandingPage | null;
};
export type AdPreviewWrite = {
  headline?: string;
  logo: string | null;
  short_url: string;
  source_url: string;
  summary?: string;
  publication: string;
  tenant: string;
  tenant_slug: string;
  tenant_logo: string | null;
  authors: string[] | null;
  /** Call to action link */
  utm_url?: string;
  date_published: string | null;
};
export type PaginatedAdList = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: Ad[];
};
export type PaginatedAdListRead = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: AdRead[];
};
export type PatchedAd = {
  name?: string;
  campaign?: number;
  article?: number | null;
  summary?: string;
  headline?: string;
  lead?: string;
  image?: number | null;
  logo?: number | null;
  status?: AdStatusEnum;
  /** Call to action link */
  utm_url?: string;
};
export type PatchedAdRead = {
  id?: number;
  name?: string;
  campaign?: number;
  article?: number | null;
  summary?: string;
  headline?: string;
  lead?: string;
  image?: number | null;
  logo?: number | null;
  short_url?: string;
  status?: AdStatusEnum;
  status_display?: string;
  /** Call to action link */
  utm_url?: string;
  landing_page?: AdLandingPage | null;
  updated_at?: string;
};
export type AdComments = {
  summary?: string;
};
export type AdCommentsRead = {
  id: number;
  author: string;
  summary?: string;
  childs: string;
};
export type AudienceTargetingCriteria = {
  exclude?: boolean;
};
export type AudienceFacet = {
  name: string;
};
export type AudienceFacetRead = {
  id: number;
  name: string;
};
export type EntityTypeEnum =
  | "AGE_RANGE"
  | "COUNTRY_GROUP"
  | "COMPANY"
  | "COMPANY_SIZE"
  | "DEGREE"
  | "FIELD_OF_STUDY"
  | "FUNCTION"
  | "GENDER"
  | "GEO"
  | "GROWTH_RATE"
  | "GROUP"
  | "INDUSTRY"
  | "INTEREST"
  | "LOCALE"
  | "MEMBER_BEHAVIOR"
  | "ORGANIZATION_RANKING_LIST"
  | "REVENUE"
  | "SCHOOL"
  | "SENIORITY"
  | "SKILL"
  | "STAFF_COUNT_RANGE"
  | "TITLE"
  | "YEARS_OF_EXPERIENCE";
export type AudienceEntity = {
  name: string;
  entity_type: EntityTypeEnum;
};
export type AudienceEntityRead = {
  id: number;
  name: string;
  entity_type: EntityTypeEnum;
  entity_type_display: string;
};
export type AudienceTargetingCriteriaRead = {
  facet: AudienceFacetRead;
  entity: AudienceEntityRead;
  exclude?: boolean;
};
export type AudienceTargetingCriteriaWrite = {
  facet_id: number;
  entity_id: number;
  exclude?: boolean;
};
export type Audience = {
  name?: string;
  targeting_criteria: AudienceTargetingCriteria[];
};
export type AudienceRead = {
  id: number;
  tenant: number;
  name?: string;
  targeting_criteria: AudienceTargetingCriteriaRead[];
};
export type AudienceWrite = {
  name?: string;
  targeting_criteria: AudienceTargetingCriteriaWrite[];
};
export type PaginatedAudienceList = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: Audience[];
};
export type PaginatedAudienceListRead = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: AudienceRead[];
};
export type PaginatedAudienceListWrite = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: AudienceWrite[];
};
export type PatchedAudience = {
  name?: string;
  targeting_criteria?: AudienceTargetingCriteria[];
};
export type PatchedAudienceRead = {
  id?: number;
  tenant?: number;
  name?: string;
  targeting_criteria?: AudienceTargetingCriteriaRead[];
};
export type PatchedAudienceWrite = {
  name?: string;
  targeting_criteria?: AudienceTargetingCriteriaWrite[];
};
export type PaginatedAudienceFacetList = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: AudienceFacet[];
};
export type PaginatedAudienceFacetListRead = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: AudienceFacetRead[];
};
export type PaginatedAudienceEntityList = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: AudienceEntity[];
};
export type PaginatedAudienceEntityListRead = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: AudienceEntityRead[];
};
export type AudienceGenerationInput = {
  content?: string;
  article?: number;
};
export type CampaignGroup = {
  name?: string;
  status?: StatusC58Enum;
  /** Total Budget */
  total_budget?: string | null;
  currency?: CurrencyEnum;
  start_date?: string;
  /** Set null for continuous campaign */
  end_date?: string | null;
};
export type CampaignGroupRead = {
  id: number;
  tenant: number;
  name?: string;
  status?: StatusC58Enum;
  /** Total Budget */
  total_budget?: string | null;
  currency?: CurrencyEnum;
  start_date?: string;
  /** Set null for continuous campaign */
  end_date?: string | null;
  campaigns: CampaignRead[];
};
export type PaginatedCampaignGroupList = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: CampaignGroup[];
};
export type PaginatedCampaignGroupListRead = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: CampaignGroupRead[];
};
export type PatchedCampaignGroup = {
  name?: string;
  status?: StatusC58Enum;
  /** Total Budget */
  total_budget?: string | null;
  currency?: CurrencyEnum;
  start_date?: string;
  /** Set null for continuous campaign */
  end_date?: string | null;
};
export type PatchedCampaignGroupRead = {
  id?: number;
  tenant?: number;
  name?: string;
  status?: StatusC58Enum;
  /** Total Budget */
  total_budget?: string | null;
  currency?: CurrencyEnum;
  start_date?: string;
  /** Set null for continuous campaign */
  end_date?: string | null;
  campaigns?: CampaignRead[];
};
export type EventTypeEnum =
  | "PAGE_VIEW"
  | "ARTICLE_LINK_CLICK"
  | "CALL_TO_ACTION_CLICK";
export type EventTracker = {
  event_type: EventTypeEnum;
  ad_short_url: string;
  extra_data?: {
    [key: string]: any;
  };
};
export const {
  useCampaignsListQuery,
  useCampaignsCreateMutation,
  useCampaignsRetrieveQuery,
  useCampaignsUpdateMutation,
  useCampaignsPartialUpdateMutation,
  useCampaignsDestroyMutation,
  useCampaignsAnalyticsRetrieveQuery,
  useCampaignsDemographicsListQuery,
  useCampaignsDuplicateCreateMutation,
  useCampaignsAdRedirectRetrieveQuery,
  useCampaignsAdsListQuery,
  useCampaignsAdsCreateMutation,
  useCampaignsAdsRetrieveQuery,
  useCampaignsAdsUpdateMutation,
  useCampaignsAdsPartialUpdateMutation,
  useCampaignsAdsDestroyMutation,
  useCampaignsAdsAnalyticsRetrieveQuery,
  useCampaignsAdsCommentsListQuery,
  useCampaignsAdsDemographicsListQuery,
  useCampaignsAdsLandingpageCreateMutation,
  useCampaignsAdsImagePreviewRetrieveQuery,
  useCampaignsAudiencesListQuery,
  useCampaignsAudiencesCreateMutation,
  useCampaignsAudiencesRetrieveQuery,
  useCampaignsAudiencesUpdateMutation,
  useCampaignsAudiencesPartialUpdateMutation,
  useCampaignsAudiencesDestroyMutation,
  useCampaignsAudiencesFacetsListQuery,
  useCampaignsAudiencesFacetsEntitiesListQuery,
  useCampaignsAudiencesFacetsEntitiesRetrieveQuery,
  useCampaignsAudiencesFacetsRetrieveQuery,
  useCampaignsAudiencesGenerateCreateMutation,
  useCampaignsGroupsListQuery,
  useCampaignsGroupsCreateMutation,
  useCampaignsGroupsRetrieveQuery,
  useCampaignsGroupsUpdateMutation,
  useCampaignsGroupsPartialUpdateMutation,
  useCampaignsGroupsDestroyMutation,
  useCampaignsTrackCreateMutation,
} = injectedRtkApi;
