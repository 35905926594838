import { graphql } from "../graphql";
import { tierConfigurationFragment } from "./tier";

const FeedFragment = graphql(`
  fragment FeedFragment on FeedType @_unmask {
    id
    name
  }
`);

export const FeedOverviewQuery = graphql(
  `
  query FeedOverviewQuery {
    feeds {
      ...FeedFragment
      updatedAt
      jobLastRun
    }
  }
`,
  [FeedFragment],
);

export const SideBarDataQuery = graphql(
  `
    query SideBarDataQuery {
        feeds {
          ...FeedFragment
          terms {
            term
          }
          knownTags {
            tag
          }
        }
        campaigns {
            id
            name
        }
    }
`,
  [FeedFragment],
);

export type SideBarQueryFeedType = ReturnType<
  Exclude<typeof SideBarDataQuery.__apiType, undefined>
>["feeds"];

export type SideBarQueryCampaignsType = ReturnType<
  Exclude<typeof SideBarDataQuery.__apiType, undefined>
>["campaigns"];

export const NewsFeedsDataQuery = graphql(`
query NewsFeedsDataQuery($dayFilter: Int) {
  newsfeedData(daysToFilter: $dayFilter) {
    id
    feedName
    jobLastRun
    feedItems {
      id
      articleId
      summary
      imageUrl
      headline
      maxScore
      maxSocial
      prominence
      storyArticleCount
      storyChartIds
      storyChartScores
      storyFirstArticlePublish
      itemType
      articleLastUpdateDate
      articleReadership
      maxDomainAuthority
      storyLastArticlePublish
      articleUrl
      articleNewsCategory
      storyPublishers {
        iconUrl
        name
      }
      articlePublisher {
        name
        url
        logoUrl
      }
      articleMentions {
        snippet
        term
        location
      }
      storyPublishers {
        iconUrl
        name
      }
      discoveredTags {
        id
        tag
        tagKind
      }
      articleAuthors {
        id
        name
      }
    }
  }
}
`);

export const NewsFeedsSearchQuery = graphql(`
query NewsFeedSearchQuery($feedId: Int!, $searchTerm: String!, $selectedItems: [Int!]!) {
  feedSearch(
    input: {
      search: {
        feedId: $feedId,
        searchTerm: $searchTerm,
        selectedItems: $selectedItems,
        advancedSearch: true
      }
    }
  ) {
    term
    items {
      id
      field {
        name
        content
      }
    }
  }
}
`);

export type NewsFeedsSearchQueryType = ReturnType<
  Exclude<typeof NewsFeedsSearchQuery.__apiType, undefined>
>;

export const UpdateArticleMutation = graphql(`
  mutation UpdateArticleMutation($input: OverrideFeedArticleInput!) {
     overrideFeedArticle(
      input: $input
    ) {
      overrides
    }
  }
`);

export const NewsFeedsListQuery = graphql(
  `
query NewsFeedQuery($feedId: Int!, $sortOrder: SortOrder, $dayFilter: Int, $startDate: Date, $endDate: Date) {
  feedData(feedId: $feedId, sortOrder: $sortOrder, daysToFilter: $dayFilter, startDate: $startDate, endDate: $endDate) {
    id
    feedName
    feedTerms
    knownTags
    feedItems {
      id
      articleId
      summary
      imageUrl
      headline
      maxScore
      maxSocial
      prominence
      storyArticleCount
      storyChartIds
      storyChartScores
      storyFirstArticlePublish
      itemType
      articleLastUpdateDate
      articleReadership
      maxDomainAuthority
      storyLastArticlePublish
      articleUrl
      articleNewsCategory
      knownTags
      storyPublishers {
        iconUrl
        name
      }
      articlePublisher {
        id
        name
        url
        logoUrl
      }
      articleMentions {
        snippet
        term
        location
      }
      storyPublishers {
        iconUrl
        name
      }
      discoveredTags {
        id
        tag
        tagKind
      }
      articleAuthors {
        id
        name
      }
    }
    feedName
  }
  readershipConfiguration {
    ...TierConfig
    tenant {
      enableReadership
    }
  }
  tierConfiguration {
    ...TierConfig
    tenant {
      enableCustomTierScoring
    }
  }
  tier1Pub: publisherConfiguration(tier: TIER_ONE) {
    __typename
    publishers {
        id
      }
  }
  tier2Pub: publisherConfiguration(tier: TIER_TWO) {
    __typename
    publishers {
        id
      }
  }
  tier3Pub: publisherConfiguration(tier: TIER_THREE) {
    __typename
    publishers {
        id
      }
  }
}
  `,
  [tierConfigurationFragment],
);

export type DomainOrReadershipConfig =
  | ReturnType<
      Exclude<typeof NewsFeedsListQuery.__apiType, undefined>
    >["readershipConfiguration"]
  | ReturnType<
      Exclude<typeof NewsFeedsListQuery.__apiType, undefined>
    >["tierConfiguration"];

export const NewsFeedPublishers = graphql(`
  query NewsFeedPublishers($pubFilter: String = "", $pubLimit: Int = 50) {
    publishers(pagination: {limit: $pubLimit} filter: $pubFilter) {
      id
      name
      url
      logos {
        image {
          url
        }
      }
    }
}
`);

export type NewsFeedPublishersDataType = ReturnType<
  Exclude<typeof NewsFeedPublishers.__apiType, undefined>
>["publishers"];

export type NewsFeedPublisherType = NewsFeedPublishersDataType[number];

export type NewsFeedDataType = ReturnType<
  Exclude<typeof NewsFeedsDataQuery.__apiType, undefined>
>["newsfeedData"][number];

export type FeedDataType = ReturnType<
  Exclude<typeof NewsFeedsListQuery.__apiType, undefined>
>["feedData"];

export type FeedType = ReturnType<
  Exclude<typeof NewsFeedsListQuery.__apiType, undefined>
>["feedData"]["feedItems"][number];

export type ArticlePublisherType = FeedType["articlePublisher"];

export interface FeedWithAugTypes extends FeedType {
  searchHit?: {
    mention: string;
    term: string;
  };
  tierLevel?: string;
}

export const deleteFeedArticleMutation = graphql(`
mutation deleteFeedArticle($input: DeleteFeedArticleInput!) {
  deleteFeedArticle(input: $input) {
    message
  }
}
`);

export type DeleteExecuteType = Exclude<
  typeof deleteFeedArticleMutation.__apiType,
  undefined
>;

export type DeleteFeedArticleType =
  ReturnType<DeleteExecuteType>["deleteFeedArticle"];

export type DeleteFeedArticleParemeters =
  Parameters<DeleteExecuteType>[0]["input"];

export const FEED_MUTATION = graphql(`
    mutation FeedMutation($data: FeedUpdateInput!, $feedId: Int!) {
      updateFeed(data: $data, id: $feedId) {
        ... on FeedType {
          terms {
            id
            term
          }
          knownTags {
            id
            tag
          }
        }
      }
    }
  `);

export const CREATE_FEED_MUTATION = graphql(`
    mutation CreateFeedMutation($data: FeedCreateInput!) {
      createFeed(data: $data) {
        ... on FeedType {
          id
          name
          terms {
            term
            id
          }
          knownTags {
            id
            tag
          }
        }
      }
    }
  `);

export const UPDATE_KNOWN_TAGS_MUTATION = graphql(`
    mutation UpdateKnownTags($data: FeedUpdateInput!, $feedId: Int!) {
      updateFeed(data: $data, id: $feedId) {
        ... on FeedType {
          knownTags {
            id
            tag
          }
        }
      }
    }
  `);
