import { isProduction } from "@/config";
import useAuth from "@/store/authSlice";
import { GrowthBook } from "@growthbook/growthbook-react";
import { useEffect } from "react";
import type { AppFeatures } from "./generated-types/app-features";

export const gb = new GrowthBook<AppFeatures>({
  apiHost: "https://cdn.growthbook.io",
  clientKey: import.meta.env.VITE_GROWTHBOOK_CLIENT_KEY,
  enableDevMode: !isProduction,
  subscribeToChanges: true,
  trackingCallback: (experiment, result) => {
    if (typeof window?.gtag === "function") {
      window.gtag("event", "experiment_viewed", {
        event_category: "experiment",
        experiment_id: experiment.key,
        variation_id: result.variationId,
      });
    } else {
      isProduction && console.error("no gtag");
    }
  },
});

export const useGrowthBook = () => {
  const { loggedIn, id, email, company, employee } = useAuth((state) => ({
    ...state.user,
    loggedIn: state.loggedIn,
    company: state.user.default_tenant?.slug,
    employee: state.isEmployee,
  }));

  useEffect(() => {
    gb.loadFeatures();
  }, []);

  useEffect(() => {
    gb.setAttributes({
      id,
      loggedIn,
      email,
      company,
      employee,
    });
  }, [loggedIn, id, email, company, employee]);

  return gb;
};
