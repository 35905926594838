import { datadogRum } from "@datadog/browser-rum";
import ReactGA from "react-ga";

export const initializeAnalytics = () => {
  ReactGA.initialize("G-DWBS59VVDF");

  datadogRum.init({
    applicationId: "cad0c07e-468f-41c0-a96a-b271c04d6325",
    clientToken: "pubbdfc955a344c1f8eae4f7b37d55c10e5",
    site: "us5.datadoghq.com",
    service: "newsamp",
    env: `${import.meta.env.VITE_ENVIRONMENT}`,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    premiumSampleRate: 100,
    trackUserInteractions: true,
    trackFrustrations: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    enableExperimentalFeatures: ["feature_flags"],
  });

  datadogRum.startSessionReplayRecording();
};
