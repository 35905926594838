import { useFeature } from "@/lib/feature-management";
import { cn } from "@/lib/utils";
import { CaretLeft, NewspaperClipping } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Button } from "../ui/controls/button";

const items = [
  { name: "General", link: "/settings" },
  { name: "Billing", link: "/billing" },
];

const SettingsSidebar = () => {
  const navigate = useNavigate();
  const hasBilling = useFeature("has-billing", false);
  const childNavStyle = ({ isActive }: { isActive: boolean }) =>
    cn(
      "flex items-center pl-6 pr-2 py-1 h-8 rounded",
      "transition-colors text-slate-900",
      isActive && "bg-slate-200/60 font-semibold text-slate-800",
      "hover:bg-slate-200",
      "truncate",
    );

  return (
    <aside className="h-screen bg-slate-100 border-r border-slate-200 py-4 flex flex-col duration-500 w-56">
      <nav className="p-5 text-sm flex flex-col gap-8 items-start">
        <Button
          variant="ghost"
          leftIcon={<CaretLeft />}
          size="inline"
          onClick={() => navigate("/")}
        >
          Settings
        </Button>
        <div className="flex flex-col w-full gap-0.5">
          <div className="flex items-center py-1 px-2 gap-3">
            <NewspaperClipping />
            <span>Account Settings</span>
          </div>
          {hasBilling &&
            items.map((item) => (
              <NavLink
                key={item.name}
                to={item.link}
                className={({ isActive }) => childNavStyle({ isActive })}
              >
                <span className="ml-2 truncate" title={item.name}>
                  {item.name}
                </span>
              </NavLink>
            ))}
        </div>
      </nav>
    </aside>
  );
};

export default SettingsSidebar;
