import type { Reducer } from "redux";

import type { UserInfo } from "@/types/user/userInfo";
import { types } from "../../types/types";

interface AccountState {
  user?: UserInfo | null;
  profilePicture?: string | null;
  passwordUpdated?: boolean;
  logo?: string | null;
  company?: {
    name: string;
    logo?: string;
  };
}

const initialState: AccountState = {
  user: null,
  profilePicture: null,
  logo: null,
};

type AccountAction = { type: string; payload?: any };

export const accountReducer: Reducer<AccountState, AccountAction> = (
  state: AccountState | undefined,
  action: AccountAction,
) => {
  switch (action.type) {
    case types.accountSetTmpPicture:
      return {
        ...state,
        profilePicture: action.payload,
      };
    case types.accountUploadCompanyLogo:
      return {
        ...state,
        logo: action.payload,
      };
    case types.accountUpdateCompany:
      return {
        ...state,
        company: action.payload,
      };
    case types.accountLoadCompany:
      return {
        ...state,
        company: action.payload,
      };
    case types.accountReset:
      return {
        ...state,
        user: null,
        profilePicture: null,
        passwordUpdated: false,
        logo: null,
        company: undefined,
      };

    default:
      return state || initialState;
  }
};
