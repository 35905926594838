import {
  SideBarDataQuery,
  type SideBarQueryCampaignsType,
  type SideBarQueryFeedType,
} from "@/data-access/news/newsFeeds";
import { useFeature } from "@/lib/feature-management";
import useFeedStore from "@/store/useFeedStore";
import { NewspaperClipping, RocketLaunch } from "@phosphor-icons/react";
import { useCallback, useEffect, useState } from "react";
import { useQuery } from "urql";
import DropdownList from "./dropdownList";

const SidebarNavigation = ({
  filter,
  collapsed = false,
}: { filter?: string; collapsed?: boolean }) => {
  const showNewsPage = useFeature("news-page", false);
  const showCampaignsPage = useFeature("campaigns-enabled", false);
  const [updateSideBarData] = useFeedStore((store) => [
    store.updateFeedSideBarData,
  ]);
  const [feeds, setFeeds] = useState<SideBarQueryFeedType>([]);
  const [campaigns, setCampaigns] = useState<SideBarQueryCampaignsType>([]);

  const [{ data }] = useQuery({
    query: SideBarDataQuery,
    requestPolicy: "cache-and-network",
  });

  const filterItems = useCallback(
    <T extends SideBarQueryFeedType | SideBarQueryCampaignsType>(
      items: T | undefined,
    ) => {
      if (!items) return [];
      return items.filter((item) => {
        const itemName = (item.name as string).toLowerCase();
        const filterValue = filter?.toLowerCase() || "";
        return itemName.includes(filterValue);
      });
    },
    [filter],
  );

  useEffect(() => {
    if (!data) return;
    updateSideBarData(data.feeds);
  }, [data]);

  const filterFeeds = useCallback(
    (feeds: SideBarQueryFeedType) =>
      setFeeds(filterItems(feeds) as SideBarQueryFeedType),
    [data, filter],
  );

  const filterCampaigns = useCallback(
    (campaigns: SideBarQueryCampaignsType) =>
      setCampaigns(filterItems(campaigns)),
    [data, filter],
  );

  useEffect(() => {
    const feeds = data?.feeds.sort((a, b) => (a.name > b.name ? 1 : -1)) ?? [];
    const campaigns =
      data?.campaigns.sort((a, b) => (a.name > b.name ? 1 : -1)) ?? [];

    if (!filter) {
      setFeeds(feeds);
      setCampaigns(campaigns);
    } else {
      filterFeeds(feeds);
      filterCampaigns(campaigns);
    }
  }, [data, filter]);

  const openWhenFilterApplied = Boolean(filter) || undefined;

  return (
    <>
      {showNewsPage && (
        <DropdownList
          title="Newsfeeds"
          items={feeds}
          icon={NewspaperClipping}
          basePath="/newsfeeds"
          open={openWhenFilterApplied}
          collapsed={collapsed}
        />
      )}

      {showCampaignsPage && (
        <DropdownList
          title="Campaigns"
          items={campaigns}
          icon={RocketLaunch}
          basePath="/campaigns"
          open={openWhenFilterApplied}
          collapsed={collapsed}
        />
      )}
    </>
  );
};

export default SidebarNavigation;
