export interface UserInfo {
  id: number;
  name: string;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  avatar?: string | null;
  date_joined: string;
  position?: string;
  expectation?: string;
  expectationUpdated?: string;
  isExpectationUpdated?: boolean;
  walkthrough_completed?: boolean;
  tenants?: any[];
  default_tenant_id: number;
  default_tenant: {
    id: number;
    name: string;
    slug: string;
    website: string;
    owner: number;
    logo: string;
  };
}

export const defaultUserInfo: UserInfo = {
  id: 0,
  name: "",
  username: "",
  email: "",
  first_name: "",
  last_name: "",
  date_joined: "",
  isExpectationUpdated: false,
  walkthrough_completed: false,
  default_tenant_id: 0,
  default_tenant: {
    id: 0,
    name: "",
    slug: "",
    website: "",
    owner: 0,
    logo: "",
  },
};
