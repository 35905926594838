export const types = {
  accountUpdate: "[Account] Update",
  accountSetTmpPicture: "[Account] Set Tmp Picture",
  accountUploadCompanyLogo: "[Account] Upload Company Logo",
  accountUpdateCompany: "[Account] Update Company",
  accountLoadCompany: "[Account] Load Company",
  accountReset: "[Account] Reset",
  builderSetSidebarVisible: "[Builder] Set Sidebar Visible",
  builderGenerateArticle: "[Builder] Generate Article",
  builderShowSpinner: "[Builder] Show Spinner",
  builderGenerateCampaign: "[Builder] Generate Campaign",
  builderGenerateAd: "[Builder] Generate Ad",
  builderUpdateAd: "[Builder] Update Ad",
  builderReset: "[Builder] Reset",
  builderSelectCampaign: "[Builder] Select Campaign",
  builderRefreshCampaign: "[Builder] Refresh Campaign",
  builderUpdateCampaign: "[Builder] Update Campaign",
  builderLoadAnalytics: "[Builder] Load Analytics",
  builderLoadDemographics: "[Builder] Load Demographics",
  builderLoadComments: "[Builder] Load Comments",
  builderLoadMetrics: "[Builder] Load Metrics",
};
