import { isTokenExpired } from "@/lib/utils";
import useAuth from "@/store/authSlice";
import { apiEndpointBaseUrl } from "./constants";

// biome-ignore lint/suspicious/noConfusingVoidType: <explanation>
const refreshToken = async (refreshToken: string): Promise<string | void> => {
  if (isTokenExpired(refreshToken)) {
    return await useAuth.getState().logoutUser();
  }

  try {
    const response = await fetch(`${apiEndpointBaseUrl}/auth/token/refresh/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refresh: refreshToken }),
    });

    if (!response.ok) {
      throw new Error("Token refresh failed");
    }

    const body = await response.json();
    window.localStorage.setItem("access_token", body.access);
    window.localStorage.setItem("refresh_token", body.refresh);
    return body.access;
  } catch (error) {
    console.error("Error refreshing token:", error);
    await useAuth.getState().logoutUser();
    throw new Error("Error refreshing token");
  }
};

export const getAuthToken = async (
  token = window.localStorage.getItem("access_token") || "",
  // biome-ignore lint/suspicious/noConfusingVoidType: <explanation>
): Promise<string | void> => {
  if (token && !isTokenExpired(token)) return token;
  const refresh = window.localStorage.getItem("refresh_token") || "";
  if (isTokenExpired(refresh)) return await useAuth.getState().logoutUser();
  return await refreshToken(refresh);
};
