import BackgroundGradientLayout from "@/components/common/Gradient";
import { useFeature } from "@/lib/feature-management";
import useAuth from "@/store/authSlice";
import { useMemo } from "react";
import { Navigate, Outlet } from "react-router-dom";
import ForgotPasswordScreen from "./ForgotPassword";
import ForgotPasswordConfirm from "./ForgotPasswordConfirm";
import LoginScreen from "./Login";
import RegisterScreen from "./Register";

const useAuthRoutes = () => {
  const registrationEnabled = useFeature("allow-registration", false);
  const loggedIn = useAuth((state) => state.loggedIn);

  const routes = useMemo(() => {
    return {
      path: "/auth",
      element: !loggedIn ? <Outlet /> : <Navigate to="/" />,
      children: [
        {
          path: "login",
          element: (
            <BackgroundGradientLayout>
              <LoginScreen />
            </BackgroundGradientLayout>
          ),
        },
        {
          path: "forgot-password",
          element: (
            <BackgroundGradientLayout>
              <ForgotPasswordScreen />
            </BackgroundGradientLayout>
          ),
        },
        {
          path: "forgot-password-confirm",
          element: <ForgotPasswordConfirm />,
        },
        {
          path: "register",
          element: registrationEnabled ? (
            <RegisterScreen />
          ) : (
            <Navigate to="/auth/login" />
          ),
        },
        {
          path: "*",
          element: <Navigate to="/auth/login" />,
        },
      ],
    };
  }, [loggedIn, registrationEnabled]);

  return routes;
};

export default useAuthRoutes;
