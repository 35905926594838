import type { Reducer } from "redux";

import type { IAd, IAdMetrics, IAnalytics, IComments } from "@/types/ad";
import { type IArticle, defaultArticle } from "@/types/article";
import type { IDemographics } from "@/types/campaign/demographics";
import type { ILogo } from "@/types/media/logo";
import { types } from "../../types/types";
import type { CampaignRead } from "../generated-apis/campaignApi";

export interface BuilderState {
  article?: IArticle;
  showSpinner?: boolean;
  campaign?: CampaignRead;
  analytics?: IAnalytics;
  demographics: IDemographics[];
  comments: IComments[];
  adMetrics?: IAdMetrics;
  ad?: IAd;
  logos: ILogo[];
  ads: IAd[];
  campaigns: CampaignRead[];
  selectedCampaignId?: number;
  isSidebarVisible: boolean;
  isLoading: boolean;
}

const initialState: BuilderState = {
  article: defaultArticle,
  demographics: [],
  comments: [],
  logos: [],
  ads: [],
  campaigns: [],
  isSidebarVisible: true,
  isLoading: false,
};

const getState = (state: BuilderState | undefined): BuilderState =>
  state || initialState;

type BuilderAction = { type: string; payload?: any };

export const builderReducer: Reducer = (
  state: BuilderState | undefined,
  action: BuilderAction,
) => {
  switch (action.type) {
    case types.builderSetSidebarVisible:
      return {
        ...getState(state),
        isSidebarVisible: action.payload,
      };
    case types.builderGenerateArticle:
      return {
        ...getState(state),
        article: { ...action.payload },
      };
    case types.builderShowSpinner:
      return {
        ...getState(state),
        showSpinner: action.payload,
      };
    case types.builderGenerateCampaign:
      return {
        ...getState(state),
        selectedCampaignId: action.payload.id,
        campaigns: [action.payload, ...getState(state).campaigns],
      };
    case types.builderGenerateAd:
      return {
        ...getState(state),
        ad: { ...action.payload },
      };
    case types.builderUpdateAd:
      return {
        ...getState(state),
        ad: { ...action.payload },
      };
    case types.builderReset:
      return {
        ...getState(state),
        article: undefined,
        ad: undefined,
        logos: [],
        showSpinner: false,
      };
    case types.builderRefreshCampaign: {
      const campaigns = getState(state).campaigns.filter(
        (c) => c.id !== action.payload.id,
      );
      return {
        ...getState(state),
        campaigns: [...campaigns, action.payload],
      };
    }
    case types.builderSelectCampaign:
      return {
        ...getState(state),
        selectedCampaignId: action.payload,
      };
    case types.builderUpdateCampaign:
      return {
        ...getState(state),
        campaigns: getState(state).campaigns.map((c) => {
          if (c.id === action.payload.id) {
            return { ...action.payload };
          }
          return c;
        }),
      };
    case types.builderLoadAnalytics:
      return {
        ...getState(state),
        analytics: { ...action.payload },
      };
    case types.builderLoadDemographics: {
      return {
        ...getState(state),
        demographics: [...action.payload],
      };
    }
    case types.builderLoadComments: {
      return {
        ...getState(state),
        comments: [...action.payload],
      };
    }
    case types.builderLoadMetrics: {
      return {
        ...getState(state),
        adMetrics: { ...action.payload },
      };
    }
    default:
      return getState(state);
  }
};
