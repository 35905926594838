import { isDevelopment } from "@/config";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { type FeedSlice, createFeedSlice } from "./feedSlice";

const useFeedStore = create<FeedSlice>()(
  devtools((...a) => ({ ...createFeedSlice(...a) }), {
    name: "feedStore",
    enabled: isDevelopment,
    store: "feed",
  }),
);

export default useFeedStore;
