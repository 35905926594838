import { GrowthBookProvider } from "@growthbook/growthbook-react";
import React from "react";
import { useGrowthBook } from "./useGrowthbook";

export const FeatureManagementProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const gb = useGrowthBook();
  return <GrowthBookProvider growthbook={gb}>{children}</GrowthBookProvider>;
};
