import { isLocal } from "@/config";
import React, { useEffect } from "react";
import { initializeAnalytics } from "./initializeAnalytics";
import initializeLogging from "./initializeLogging";
import { useUserScope } from "./useUserScope";

export const AnalyticsProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  useEffect(() => {
    if (!isLocal) {
      initializeLogging();
      initializeAnalytics();
    }
  }, []);

  useUserScope();
  return <>{children}</>;
};
